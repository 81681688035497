import * as dayjs from 'dayjs';

export const COLUMNS_LIST = [
    {
        id: 'box_id',
        name: 'BOX_ID',
        show_search: true,
        width: '100px',
    },
    {
        id: 'variant_image',
        name: '',
        width: '60px',
    },
    {
        id: 'product_sku',
        name: 'SKU_&_VARIANT',
        show_search: true,
        width: '150px',
    },
    {
        id: 'shop_name',
        name: 'SHOP',
        show_search: true,
        width: '150px',
    },
    {
        id: 'product_name',
        name: 'PRODUCT_NAME_&_ID',
        show_search: true,
    },

    {
        id: 'warehouse_name',
        name: 'WAREHOUSE',
        listOfFilter: [
            { text: 'SF Warehouse', value: 'SF Warehouse' },
            { text: 'HZ Warehouse', value: 'HZ Warehouse' },
            { text: 'Lifo Test', value: 'Lifo Test' },
        ],
        filterFn: (list: string[], item) => list.some(name => item.warehouse_name.indexOf(name) !== -1),
    },
    {
        id: 'quantity',
        name: 'QUANTITY_SHIPPED/ARRIVED',
        sortFn: (a, b) => a.quantity - b.quantity,
    },
    {
        id: 'shipping_method',
        name: 'SHIPPING_METHOD',
        listOfFilter: [
            { text: '快递', value: '快递' },
            { text: '空派', value: '空派' },
            { text: '海派', value: '海派' },
            { text: 'Other/其他', value: 'Other/其他' },
        ],
        filterFn: (method: string, item) => (item.shipping_method ? item.shipping_method.indexOf(method) !== -1 : false),
    },
    {
        id: ['tracking_number', 'carrier'],
        name: 'TRACKING',
        show_search: true,
    },
    {
        id: 'shipping_cost',
        name: 'SHIPPING_COST',
    },
    {
        id: 'logistics_type',
        name: 'LOGISTICS_TYPE',
        listOfFilter: [
            { text: 'International logistics 国际物流', value: 'international' },
            { text: 'US Purchase 北美单程采购&物流', value: 'us' },
        ],
        filterFn: (logistic: string, item) => (item.logistics_type ? item.logistics_type.indexOf(logistic) !== -1 : false),
    },
    {
        id: 'shipping_date',
        name: 'SHIPPING_DATE',
        defaultSortDate: 'ascend',
        sortFn: (a, b) => new Date(a.shipping_date).getTime() - new Date(b.shipping_date).getTime(),
    },
    {
        id: 'deliver_eta',
        name: 'DELIVER_ETA',
        // defaultSort: 'ascend',
        sortFn: (a, b) => new Date(a.deliver_eta).getTime() - new Date(b.deliver_eta).getTime(),
    },
    {
        id: 'latest_shipping_status',
        name: 'SHIPPING_STATUS',
        defaultSort: 'descend',
        sortFn: (a, b) => a.trackingStatusCode - b.trackingStatusCode,
        // sortFn: (a, b) =>
        //     !a.is_delivered && b.is_delivered
        //         ? 1
        //         : a.is_delivered && b.is_delivered && dayjs(a.deliver_eta).isAfter(dayjs(b.deliver_eta))
        //         ? -1
        //         : a.is_delivered && !b.is_delivered
        //         ? -1
        //         : 0,
        width: '210px',
    },
    {
        id: 'restock_request_id',
        name: 'RESTOCK_REQUEST_ID',
        show_search: true,
        width: '100px',
    },
    {
        id: 'product_purchase_id',
        name: 'PURCHASE_ID',
        show_search: true,
        width: '100px',
    },
    {
        id: 'is_delivered',
        name: 'CHECKPOINT',
        width: '230px',
        sortFn: (a, b) => (a.is_delivered ? 1 : -1),
        nzRight: true,
    },
];

export const PRODUCT_INFO_LIST = [
    {
        valueKey: ['variant_name', 'product_sku'],
        label: 'SKU & Variant 规格',
        type: 'multiple',
        split: '<br/>',
    },
    {
        valueKey: 'shop_name',
        label: 'Shop 店铺',
    },
    {
        valueKey: ['product_name', 'product_id'],
        label: 'Product Name & ID 产品 & ID',
        type: 'multiple',
        split: '<br/>',
    },
    {
        valueKey: 'warehouse_name',
        label: 'Warehouse 仓库',
    },
    {
        valueKey: ['shipped_quantity', 'arrived_quantity'],
        label: 'Quantity Shipped/Arrived 发货数/到货数',
        type: 'multiple',
        split: ' / ',
    },
    {
        valueKey: 'shipping_method',
        label: 'Shipping Method 发货方式',
        default: '待回填',
    },
    {
        valueKey: ['carrier', 'tracking_number'],
        label: 'Tracking 物流信息',
        type: 'multiple',
        split: ' / ',
    },
    {
        valueKey: 'shipping_cost',
        label: 'Shipping Cost 运费',
        type: 'cost',
    },
    {
        format: data => (data.logistics_type === 'us' ? 'US Purchase 北美单程采购' : 'International logistics 国际物流'),
        label: 'Logistics type 物流类型',
        type: 'format',
    },
    {
        valueKey: 'shipping_date',
        label: 'Shipping Date 发货日期',
        type: 'time',
    },
    {
        valueKey: 'deliver_eta',
        label: 'Deliver ETA 预计到货日期',
        type: 'time',
    },
    {
        format: data => data.latest_shipping_status?.status || data.latest_shipping_status?.statusCode || 'Unknown',
        label: 'Shipping Status 物流状态',
        type: 'format',
    },
    {
        valueKey: 'restock_request_id',
        label: 'Restock Request ID 补货请求ID',
        default: '---',
    },
    {
        valueKey: 'product_purchase_id',
        label: 'Purchase ID 采购单号',
        default: '---',
    },
];
