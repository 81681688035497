import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { SignInComponent } from '@pages/auth/sign-in/sign-in.component';
import { SubmenuComponent } from '@shared/components/submenu/submenu.component';

import { AuthGuard } from '@shared/guards/auth.guard';

import { UserPermissionGuard } from '@services/user-permission.guard';

import { InternalPermissionGuard } from '@shared/guards/role.guard';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
    { path: 'sign-in', canActivate: [AuthGuard], component: SignInComponent },
    {
        path: '',
        component: SubmenuComponent,
        canActivate: [AuthGuard, InternalPermissionGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

            { path: 'dashboard', component: DashboardComponent },

            {
                path: 'product-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/product-management/product-management.module').then(m => m.ProductManagementModule),
            },

            {
                path: 'faqs-setting',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/faqs-setting/faqs-setting.module').then(m => m.FaqsSettingModule),
            },

            {
                path: 'order-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/order-management/order-management.module').then(m => m.OrderManagementModule),
            },

            {
                path: 'shop-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/shop-management/shop-management.module').then(m => m.ShopManagementModule),
            },

            {
                path: 'co-sell',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/co-sell/co-sell.module').then(m => m.CoSellModule),
            },

            {
                path: 'purchase-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/purchase-management/purchase-management.module').then(m => m.PurchaseManagementModule),
            },

            {
                path: 'logistics-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () =>
                    import('./pages/logistics-management/logistics-management.module').then(m => m.LogisticsManagementModule),
            },

            {
                path: 'inventory-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () =>
                    import('./pages/inventory-management/inventory-management.module').then(m => m.InventoryManagementModule),
            },

            {
                path: 'warehouse-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () =>
                    import('./pages/warehouse-management/warehouse-management.module').then(m => m.WarehouseManagementModule),
            },

            {
                path: 'standard-product',
                canActivate: [UserPermissionGuard],
                loadChildren: () =>
                    import('./pages/standard-product-management/standard-product-management.module').then(
                        m => m.StandardProductManagementModule
                    ),
            },
            {
                path: 'payment-management',
                canActivate: [UserPermissionGuard],
                loadChildren: () => import('./pages/payment-management/payment-management.module').then(m => m.PaymentManagementModule),
            },

            {
                path: '**',
                redirectTo: '/sign-in',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
