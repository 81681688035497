import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FilterItem, SearchOption } from './search-filter.type';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.less'],
})
export class SearchFilterComponent implements OnInit, OnChanges {
    @Input() searchParams: { searchValue: string; selectedCategory: string };
    @Input() filterList: FilterItem[];
    @Input() searchOptions: SearchOption[];
    @Input() isShowFilterBtns = true;
    @Input() isShowDatePicker = false;

    @Output() searchFilterChange = new EventEmitter<void>();

    // [Name1, Name2, Name...]
    filterParamsOfTag = new Map<FilterItem, string[]>();
    dateFormat = 'MM/dd/yyyy';
    dateTimes = [];

    get hasFilter() {
        return this.filterList?.some(filter => filter.selectedOptionIds?.length > 0);
    }

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.hasFilter) {
            this.filterUpdate();
        }
    }

    clearSearch() {
        this.searchParams.searchValue = '';
        this.searchFilterChange.emit();
    }

    onChangeDate() {
        const startDate = dayjs(this.dateTimes[0]).format('YYYY-MM-DD');
        const endDate = dayjs(this.dateTimes[1]).format('YYYY-MM-DD');
        this.filterUpdate();
    }

    reset() {
        this.filterList.forEach(filter => (filter.selectedOptionIds = []));
        this.searchParams.searchValue = '';
        this.searchParams.selectedCategory = this.searchOptions[0].id;
        this.searchFilterChange.emit();
    }

    startSearch() {
        const isValueEmpty = !this.searchParams.selectedCategory;
        if (isValueEmpty) {
            return;
        }
        this.searchFilterChange.emit();
    }

    filterUpdate() {
        this.filterList
            .filter(filter => filter.selectedOptionIds.length > 0)
            .forEach(filter => {
                const names = [];
                this.filterParamsOfTag.set(filter, names);
                filter.selectedOptionIds.forEach(id => names.push(filter.options.find(option => option.id === id)?.name));
            });
        this.searchFilterChange.emit();
    }

    removeFilterTag(filter: FilterItem) {
        filter.selectedOptionIds = [];
        this.searchFilterChange.emit();
    }
}
