import { cloneDeep } from 'lodash';

export interface Inventory {
    alert_threshold: number;
    inventory: number;
    inventory_id: string;
    warehouse_id: string;
    warehouse_name: string;
}

export interface ProductInventory {
    product_id: string;
    title: string;
    product_type: ProductTypeEnum;
    vendor: string;
    shop_name: string;
    shop_id: any;
    image: string;
    sku_list: string[];
    warehouse_list: string[];
    active: boolean;
}

export interface SkuInventory {
    id: string | number;
    sku: string;
    inventory_list: Inventory[];
    variant_json: any;
}

export interface ProductInventoryFilterItem {
    id: string;
    name: string;
}

export enum KeysUsedForFilterEnum {
    TYPE = 'product_type',
    BRAND = 'vendor',
    WAREHOUSE = 'warehouse_id',
}

export enum ProductTypeEnum {
    PRODUCT = 'product',
    CUSTOM_PRODUCT = 'customize_product',
    STANDARD_PRODUCT = 'standard_product',
    DIY_PRODUCT = 'diy_product',
    WRAPPER = 'wrapper',
    INSERT = 'insert',
    PACKAGE = 'package',
    STICKER = 'sticker',
}

export class ModalConfiguration<FormDataType> {
    visible = false;
    confirmLoading = false;
    confirmDisable: any; // boolean || ()=>void
    formData: FormDataType;

    private initialFormData: any;

    constructor(params: { visible?: boolean; confirmLoading?: boolean; confirmDisable?: any; formData?: FormDataType }) {
        const { visible, confirmLoading, confirmDisable, formData } = params;

        if (visible !== undefined) {
            this.visible = visible;
        }

        if (confirmLoading !== undefined) {
            this.confirmLoading = confirmLoading;
        }

        this.confirmDisable = confirmDisable;
        this.formData = formData;
        this.initialFormData = cloneDeep(this.formData);
    }

    resetFormData() {
        this.formData = cloneDeep(this.initialFormData);
    }
}

export enum OrderStatusEnum {
    OPEN = 'open',
    CANCELED = 'canceled',
}

export enum WarehouseStatusEnum {
    FAILED = 'failed',
    SUCCESS = 'success',
}

export interface OrderItem {
    order_id: string;
    created_at: string;
    financial_status: string;
    fulfillment_status: string;

    line_items_count: string;
    name: string;
    order_type: string;
    original_shop_id: string;
    original_shop_name: string;
    original_order_id: string;
    total_price: number;
    updated_at: string;
    warehouse_id: string;
    warehouse_name: string;
    order_status: OrderStatusEnum;
    warehouse_order_status: WarehouseStatusEnum;
    last_tracked_at: string;
    delivered_at: string;
    fulfillment_created_at: string;

    // Only frontEnd
    shippingStatus?: 'normal' | 'abnormal';
}

export interface OrderDetail extends OrderItem {
    order_detail: any;
    original_order_detail: any;
    line_items: {
        id: number;
        image: string;
        product_id: string;
        product_type: ProductTypeEnum;
        sku: string;
        title: string;
        vendor: string;
        price: number;
        quantity: number;
        total_discount: number;
        has_sufficient_inventory: boolean;
        inventory?: {
            sf_warehouse: number;
            hz_warehouse: number;
        };
    }[];
    resolutions?: any[];
    internal_order_status?: string;
    exception?: {
        created_at?: string;
        created_by?: string;
        customer_care?: boolean;
        exception_note?: string;
        exception_status?: InternalOrderStatusEnum;
        resolution_note?: string;
        resolution_status?: string;
    };
}

export interface ShopItem {
    created_at: string;
    email: string;
    name: string;
    phone: string;
    shop_detail: any;
    shop_id: number;
    shop_owner: string;
    shop_type: string;
    updated_at: string;
    account_manager: string;
    sourcing_manager: string;
    is_occupied: boolean;
    setup_complete: boolean;
}

export interface ShopSettingsDetail extends ShopItem {
    label_list: any;
    name: string;
    domain: string;
    amazon_affiliate_link: string;
    notes: string;
    id: number;
    ship_to: string;
    user_id: string;
    invitation_code: {
        invitation_code: string;
        is_redeemed: boolean;
    };
    shop_packages: {
        package_type: ProductTypeEnum;
        package_product_sku: string;
        package_product_title: string;
        package_product_image: string;
        is_default: boolean;
        is_selected: boolean;
    }[];
}

export interface AmazonSourcingDetail {
    id: number;
    image: string[];
    product_name: string;
    product_link: string;
    created_at: string;
    created_by: string;
}

export interface ShopifyProductDetail {
    admin_graphql_api_id: string;
    body_html: string;
    created_at: string;
    handle: string;
    id: number;
    product_id?: number;
    image: ShopifyImage;
    images: ShopifyImage[];
    options: any[];
    product_type: string;
    published_at: string;
    published_scope: string;
    tags: string;
    template_suffix: string;
    title: string;
    updated_at: string;
    variants: any[];
    label_list?: any[];
    vendor: string;
    commission?: number;
    discount?: number;
    active: boolean;
    editable?: boolean;
}

export interface ShopifyImage {
    admin_graphql_api_id: string;
    alt: string;
    created_at: string;
    height: number;
    id: number;
    position: number;
    product_id: number;
    src: string;
    updated_at: string;
    variant_ids: any[];
    width: number;
}

export interface ProductInfo {
    img_url: string;
    title: string;
    sub_title?: string;
    sku?: string;
    product_type: ProductTypeEnum;
    product_price?: number;
    product_quantity?: number;
    has_sufficient_inventory?: boolean;
    inventory?: {
        hz_warehouse: number;
        sf_warehouse: number;
    };
}

export interface SKUItem {
    id: string | number;
    image: string;
    image_object: {
        internal_id?: any;
        path?: string;
        src: string;
    };
    editing?: boolean;
    show_in_shop: boolean;
    is_pending_submit_sku?: boolean;
    inventory_list: Inventory[];
    sku: string;
    variant_json: any;
    compare_at_price: number;
    price: number;
    quantity: number;
    unit_cost: number;
    weight_in_gram: number;
    height_in_cm: number;
    width_in_cm: number;
    depth_in_cm: number;
    option1?: any;
    option2?: any;
    option3?: any;
    sku_sourcing_urls?: { value: string }[];
    quote_cost?: number;
    warehouse_operation_cost?: number;
    shipping_cost?: number;
}

export interface ProductInventoryDetail {
    body_html: string;
    images: any[];
    image: string;
    product_id: string;
    product_type: ProductTypeEnum;
    title: string;
    vendor: string;
    shop_name: string;
    shop_id: number;
    sku_list: SKUItem[];
    product_sourcing_urls: { value: string }[];
    product_json: any;
    packaging: any[];
    active: boolean;
    batch_order_record: any;
    required_date: string;
    requested_date: string;
    batch_required_date: string;
    batch_required_warehouse_name: string;
    batch_required_warehouse_id: any;
    listing_status: 'unpublished' | 'review_in_progress' | 'published';
    sales_type: 'limited' | 'evergreen';
    new_labels: number[];
    label_list?: any[];
    standard_product_id: number;
}

export interface UploadedFile {
    url: string;
    path: string;
    type: 'image' | 'video';
    internal_id?: any;
}

export interface SkuListItem {
    id: number;
    image: string;
    product_id: string;
    product_name: string;
    product_sku: string;
    shop_id: number;
    shop_name: string;
    variant_image: string;
    variant_name: string;
    vendor: string;
}

export interface VariantOption {
    title: string;
    price: number;
    unit_cost: number;
    currency: string;
    quote_cost: number;
    quote_currency: string;
    deleted?: boolean;
    option1?: string;
    option2?: string;
    option3?: string;
    sku_sourcing_urls: { value: string }[];
}

export interface OutOfStockListItem {
    in_stock_quantity: number;
    in_transit_quantity: number;
    product_id: string;
    product_name: string;
    sku: string;
    unfulfilled_quantity: number;
    variant_image: string;
    variant_name: string;
    product_sourcing_urls?: any[];
}

export interface PendingOrderListItem {
    created_at: string;
    id: number;
    name: string;
    shop_id: number;
    shop_name: string;
    warehouse_name: string;
    waiting_time?: number;
}

export interface unfulfilledOrderItem {
    created_at: string;
    fulfillment_created_at: string;
    id: number;
    last_tracked_at: string;
    name: string;
    order_status: string;
    order_type: string;
    resolutions: any[];
    shop_id: number;
    shop_name: string;
    warehouse_created_at: string;
    warehouse_name: string;
    not_shipped_waiting_time?: number;
    custom_waiting_time?: number;
    exception?: {
        created_at: string;
        created_by: string;
        customer_care: boolean;
        exception_note: string;
        exception_status: string;
        resolution_note: string;
        resolution_status: string;
    };
}

export interface perShopOrderItem {
    shop_name: string;
    order_total: number;
    undivided_order: number;
    normal_order: number;
    preorder_order: number;
    test_order: number;
}

export enum SampleRequestStatusEnum {
    INITIATED = 'initiated',
    SENT_TO_VENDOR = 'sent_to_vendor',
    CONFIRM_SENT = 'confirm_sent',
    SAMPLE_IN_PROGRESS = 'sample_in_progress',
    DELIVER_TO_OFFICE = 'deliver_to_office',
    PRODUCT_SHIPPED = 'product_shipped',
}

export const SampleRequestStatusText = new Map([
    [SampleRequestStatusEnum.INITIATED, '发起需求'],
    [SampleRequestStatusEnum.SENT_TO_VENDOR, '需求发给供应商'],
    [SampleRequestStatusEnum.CONFIRM_SENT, '确认打样'],
    [SampleRequestStatusEnum.SAMPLE_IN_PROGRESS, '打样生产中'],
    [SampleRequestStatusEnum.DELIVER_TO_OFFICE, '到达样品中心'],
    [SampleRequestStatusEnum.PRODUCT_SHIPPED, 'QC样品已发出'],
]);

export interface Influencer {
    userId: string;
    status: string;
    city: string;
    country: string;
    gender: string;
    ageGroup: string;
    profile: any;
    stats: any;
    audience: InfluencerAudience;
    popularPosts: any[];
    recentPosts: any[];
    sponsoredPosts: any[];
    hashtags: any[];
    mentions: any[];
    contacts: any[];
    is_registered: boolean;
    complete_campaign: boolean;
    in_campaign: boolean;
    in_list: boolean;
    register_email: string;
    label_list: any;
}

export interface InfluencerAudience {
    credibility: number;
    notable: number;
    genders: InfluencerDistributionItem[];
    geoCities: InfluencerDistributionItem[];
    geoCountries: InfluencerDistributionItem[];
    ages: InfluencerDistributionItem[];
    gendersPerAge: InfluencerGenderDistributionItem[];
    brandAffinity: InfluencerDistributionItem[];
    interests: InfluencerDistributionItem[];
    audienceLookalikes: any[];
}

export interface InfluencerDistributionItem {
    code?: string;
    name?: string;
    weight: number;
}

export interface InfluencerGenderDistributionItem {
    code?: string;
    name?: string;
    male: number;
    female: number;
}
export interface SamplePurchaseItem {
    id?: number;
    product_name: string;
    shop_id: number;
    request_qty?: number;
    request_quantity?: number;
    sourcing_link: string;
    address_id?: string;
    address?: string;
    instruction: string;
    address_detail?: any;
    urgency: string;
    required_date: string;
    qc_required: boolean;
    live_demo_requried: boolean;
    photo_shoot_required: boolean;
    purchase_complete?: boolean;
    note_history?: any[];
}

export interface ExceptionOrder {
    id: string;
    order_type?: string;
    delivered_at?: string;
    exception?: {
        exception_status?: string;
        exception_note?: string;
        resolution_status?: string;
        resolution_note?: string;
        customer_care?: boolean;
    };
}

export const OrderTypeText = new Map([
    ['undivided', '未划分'],
    ['test', '测试订单'],
    ['preorder', '预售订单'],
    ['normal', '常规订单'],
]);

export enum InternalOrderStatusEnum {
    FAILED_NO_PURCHASE = 'failed_no_purchase',
    FAILED_IN_TRANSIT = 'failed_in_transit',
    LATE_FULFILLMENT = 'late_fulfillment',
    LATE_SHIPMENT = 'late_shipment',
    FULFILLMENT_NOT_UPDATE = 'fulfillment_not_update',
    OTHER = 'other',
}

export const InternalOrderStatusText = new Map([
    [InternalOrderStatusEnum.FAILED_NO_PURCHASE, '缺货，未采购'],
    [InternalOrderStatusEnum.FAILED_IN_TRANSIT, '缺货，采购在途'],
    [InternalOrderStatusEnum.LATE_FULFILLMENT, '仓库发货超时'],
    [InternalOrderStatusEnum.LATE_SHIPMENT, '物流时效超时'],
    [InternalOrderStatusEnum.FULFILLMENT_NOT_UPDATE, '物流节点2天未更新'],
    [InternalOrderStatusEnum.OTHER, '其他'],
]);

export const InternalOrderStatusList = [
    { value: InternalOrderStatusEnum.FAILED_NO_PURCHASE, name: '缺货，未采购' },
    { value: InternalOrderStatusEnum.FAILED_IN_TRANSIT, name: '缺货，采购在途' },
    { value: InternalOrderStatusEnum.LATE_FULFILLMENT, name: '仓库发货超时' },
    { value: InternalOrderStatusEnum.LATE_SHIPMENT, name: '物流时效超时' },
    { value: InternalOrderStatusEnum.FULFILLMENT_NOT_UPDATE, name: '物流节点2天未更新' },
    { value: InternalOrderStatusEnum.OTHER, name: '其他' },
];

export const ResolutionList = [
    '已通知采购人员采购',
    '已查询采购进度',
    '仓库爆仓，已跟进发货',
    '仓库丢失，已报备采购',
    '仓库损毁，已报备采购',
    '仓库漏发，已通知发出',
    '物流节点未更新，已询承运商',
    '物流节点未更新，实际已到',
    '物流异常，清关问题',
    '到达待取，已通知客户取货',
    '物流异常，货物损毁或丢件',
    '物流异常，收件信息不正确',
    '其他',
];
