import { enableProdMode, NgModuleRef, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createNewHosts } from '@angularclass/hmr';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

// eslint-disable-next-line no-var
declare var module: any;
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
async function hmrBootstrap(bootstrap: () => Promise<NgModuleRef<AppModule>>) {
    module.hot.accept();
    const ngModule = await bootstrap();
    module.hot.dispose(() => {
        const appRef = ngModule.injector.get(ApplicationRef);
        const elements = appRef.components.map(c => c.location.nativeElement);
        const makeVisible = createNewHosts(elements);
        ngModule.destroy();
        makeVisible();
    });
}

const bootstrapCall = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (module.hot) {
    hmrBootstrap(bootstrapCall);
} else {
    bootstrapCall();
}
