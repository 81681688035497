import { ProductOption } from '@shared/components/add-option-dialog/add-option-dialog.component';
import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import {
    ProductInventory,
    ProductInventoryFilterItem,
    ProductTypeEnum,
    OrderItem,
    ShopSettingsDetail,
    ShopItem,
    ProductInventoryDetail,
    ExceptionOrder,
} from '@shared/type/order-dispatch.type';
import { UtilsService } from '@services/util.service';

import { FilterItem, SearchOption } from '@shared/components/search-filter/search-filter.type';
import * as _ from 'lodash';
import * as Excel from 'exceljs';
import { environment } from '@src/environments/environment';
import { IImportHistoryElement } from '@src/app/pages/shop-management/store-management/store-management.component';

export const typeList = [
    {
        id: ProductTypeEnum.INSERT,
        name: ProductTypeEnum.INSERT,
    },
    {
        id: ProductTypeEnum.PACKAGE,
        name: ProductTypeEnum.PACKAGE,
    },
    {
        id: ProductTypeEnum.WRAPPER,
        name: ProductTypeEnum.WRAPPER,
    },
    {
        id: ProductTypeEnum.PRODUCT,
        name: ProductTypeEnum.PRODUCT,
    },
    {
        id: ProductTypeEnum.STICKER,
        name: ProductTypeEnum.STICKER,
    },
    {
        id: ProductTypeEnum.STANDARD_PRODUCT,
        name: 'Standard Product',
    },
    {
        id: ProductTypeEnum.DIY_PRODUCT,
        name: 'DIY Product',
    },
];

interface inventoryAdjustmentToBeSaved {
    sku: string;
    adjustment: number;
}

export interface FaqToBeUpdated {
    id?: number;
    type: string;
    isShowList?: boolean;
    category: string;
    question: string;
    response: string;
}

@Injectable({
    providedIn: 'root',
})
export class OrderDispatchService {
    warehouseList: { warehouse_id: string; warehouse_name: string }[];
    selectedOrderList: number[];
    private inventoryDetail: ProductInventoryDetail;
    constructor(private requestService: RequestService, private utilService: UtilsService) {}

    set updatedInventoryDetail(inventoryDetailInput: ProductInventoryDetail) {
        this.inventoryDetail = inventoryDetailInput;
    }

    get updatedInventoryDetail() {
        return this.inventoryDetail;
    }

    hasInventoryDetail() {
        return this.inventoryDetail;
    }

    fetchProductInventoryList(): Promise<any> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/inventory',
            },
            environment.shopApiService
        );
    }

    getSearchedAndFilteredProductInventoryList(productInventoryList: ProductInventory[], searchParams: any, filterList: FilterItem[]) {
        return productInventoryList
            .filter(item => {
                const str = String(item[searchParams.selectedCategory] || '').toLowerCase();
                return str.includes(searchParams.searchValue?.toLowerCase() || '');
            })
            .filter(item =>
                filterList
                    .filter(filter => filter.selectedOptionIds.length > 0)
                    .every(filter => {
                        if (filter.key === 'warehouse_id') {
                            const warehouseIds = item.warehouse_list;
                            return filter.selectedOptionIds.every(id => warehouseIds.includes(id));
                        } else {
                            return filter.selectedOptionIds.includes(item[filter.key]);
                        }
                    })
            );
    }

    getFilterOptions(productInventoryList: ProductInventory[]) {
        const brands = [];
        const wareHouse = [];
        productInventoryList?.forEach(product => {
            const brandItem: ProductInventoryFilterItem = {
                id: product.vendor,
                name: product.vendor,
            };
            brands.push(brandItem);
        });

        const brandList = _.uniqBy(brands, 'id');

        return { brandList };
    }

    getFilterOptionsOfOrder(orders: OrderItem[]) {
        const brands = [];
        const wareHouses = [];
        orders.forEach(order => {
            const brandItem: SearchOption = {
                id: order.original_shop_id,
                name: order.original_shop_name,
            };
            const wareHouseItem: SearchOption = {
                id: order.warehouse_id,
                name: order.warehouse_name,
            };
            wareHouses.push(wareHouseItem);
            brands.push(brandItem);
        });

        const brandList = _.uniqBy(brands, 'id');
        const warehouseList = _.uniqBy(wareHouses, 'id');

        return { brandList, warehouseList };
    }

    recreateOrder(shopId: any, orderId: any) {
        return this.requestService.sendRequest<any>(
            {
                method: 'PUT',
                url: '/am/dispatcher/recreate_order',
                data: {
                    shop_id: shopId,
                    order_id: orderId,
                },
            },
            environment.shopApiService
        );
    }

    createProcessTask(orderIds, batchSize) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/order_process_task',
                data: {
                    order_ids: orderIds,
                    batch_size: batchSize,
                },
            },
            environment.shopApiService
        );
    }

    getProcessTask(taskId) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/order_process_task?task_id=${taskId}`,
            },
            environment.shopApiService
        );
    }

    getCustomToken(shopId) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/access_creator_hub?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    setSpecialOffers(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/update_product',
                data,
            },
            environment.shopApiService
        );
    }

    updateShopifyProductInfo() {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/brand/shopify_product_info',
            },
            environment.shopApiService
        );
    }

    listShopItem() {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: '/shared/list_product',
            },
            environment.shopApiService
        );
    }

    amazonSourcingRequest() {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: '/am/dispatcher/amazon_sourcing_request',
            },
            environment.shopApiService
        );
    }

    getWarehouseList() {
        return this.requestService
            .sendRequest<any>(
                {
                    method: 'GET',
                    url: '/am/dispatcher/warehouse',
                },
                environment.shopApiService
            )
            .then(res => {
                this.warehouseList = res;
                return res;
            });
    }

    createWarehouse(data: { alert_threshold: number; warehouse_id: string; inventory: number; sku: string }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/create_product_inventory',
                data,
            },
            environment.shopApiService
        );
    }

    updateSalesPrice(data: { sku_list: string[]; discount: number }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/batch_update_sales_price',
                data,
            },
            environment.shopApiService
        );
    }

    deleteWarehouse(id: string) {
        return this.requestService.sendRequest(
            {
                method: 'DELETE',
                url: `/am/dispatcher/delete_product_inventory?inventory_id=${id}`,
            },
            environment.shopApiService
        );
    }

    getAllProductSku(): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/all_sku',
            },
            environment.shopApiService
        );
    }

    getProductOrder(): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/standard_product_order',
            },
            environment.shopApiService
        );
    }

    putProductOrderPayment(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/standard_product_order_payment',
                data,
            },
            environment.shopApiService
        );
    }

    getAddressBook(): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/address_book',
            },
            environment.shopApiService
        );
    }

    createAddressBook(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/address_book',
                data,
            },
            environment.shopApiService
        );
    }

    getProductEvent(id) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/product_event?shop_id=${id}`,
            },
            environment.shopApiService
        );
    }

    deleteProductEvent(id) {
        return this.requestService.sendRequest(
            {
                method: 'DELETE',
                url: `/am/dispatcher/product_event?event_id=${id}`,
            },
            environment.shopApiService
        );
    }

    addSKUEvent(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/market_campaign',
                data,
            },
            environment.shopApiService
        );
    }

    getSKUEvent(shopId) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/market_campaign?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    getSKUShopReport(shopId) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/shop_report/${shopId}`,
                timeout: 1000 * 60 * 30,
            },
            environment.dataApiService
        );
    }

    getShopStartup(shopId) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/get_shop_startup_checklist?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    updateShopStartup(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/update_shop_startup_checklist',
                data,
            },
            environment.shopApiService
        );
    }

    postProductEvent(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/product_event',
                data,
            },
            environment.shopApiService
        );
    }

    postTrafficSources(data): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/analytics/traffic_sources',
                data,
            },
            environment.dataApiService
        );
    }

    getSampleRequest(id = null): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/sample_request?shop_id=${id}`,
            },
            environment.shopApiService
        );
    }

    createSampleRequest(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/sample_request',
                data,
            },
            environment.shopApiService
        );
    }

    getAutoRestockRequest(): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/auto_restock_request',
            },
            environment.shopApiService
        );
    }

    getProductSourcing() {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/get_sourcing_request',
            },
            environment.shopApiService
        );
    }

    getProductSourcingDetail(id) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/sourcing_request_detail?id=${id}`,
            },
            environment.shopApiService
        );
    }

    addProductSourcing(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/standard_prdocut_sourcing',
                data,
            },
            environment.shopApiService
        );
    }

    updateSourcingRequest(data: { id: number; assignee: string }) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/sourcing_request',
                data,
            },
            environment.shopApiService
        );
    }

    createStoreManagementSampleRequest(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/auto_restock_request',
                data,
            },
            environment.shopApiService
        );
    }

    confirmSellSampleRequest(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/sample_request',
                data,
            },
            environment.shopApiService
        );
    }

    getShopReport(id = 5): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/shop_report/${id}`,
                timeout: 1000 * 60 * 30,
            },
            environment.dataApiService
        );
    }

    getShopInventory(id = 5): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/get_shop_product_inventory?shop_id=${id}`,
            },
            environment.shopApiService
        );
    }

    getPaymentRequestList(): Promise<any[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/payment/records',
            },
            environment.dataApiService
        );
    }

    exportRecords(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/export_purchase_logistic',
                data,
                timeout: 1000 * 60 * 3,
            },
            environment.shopApiService
        );
    }

    createPaymentRecord(data: {
        shop_id: number;
        payment_type: string;
        amount: number;
        payment_status: string;
        invoice_image_url: string;
        payment_method: string[];
        paid_at: string;
    }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/payment/records',
                data,
            },
            environment.dataApiService
        );
    }

    getSingleSku(sku): Promise<any> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/sku_info?sku=${sku}`,
            },
            environment.shopApiService
        );
    }

    updateProductTypeByProductId(params: { product_id: string; product_type: string }) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/sku',
                data: params,
            },
            environment.shopApiService
        );
    }

    sendRestockRequest(params: { product_ids: string[]; warehouse_ids: string[] }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/send_restock_notification',
                data: params,
            },
            environment.shopApiService
        );
    }

    sendLifoCredit(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/adjustment/lifo_credit',
                data,
            },
            environment.dataApiService
        );
    }

    getFundsList(shopId) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/inf_transaction_history?transactionType=ADJUSTMENT_LIFO_CREDIT&shopId=${shopId}`,
            },
            environment.dataApiService
        );
    }

    getOrderSummary(): Promise<{
        orderList: OrderItem[];
        brandList: any;
        warehouseList: any;
    }> {
        return this.requestService
            .sendRequest(
                {
                    method: 'GET',
                    url: '/am/dispatcher/order_summary',
                },
                environment.shopApiService
            )
            .then((data: OrderItem[]) => {
                const { warehouseList, brandList } = this.getFilterOptionsOfOrder(data);
                return Promise.resolve({
                    orderList: data,
                    brandList,
                    warehouseList,
                });
            });
    }

    getOrderDetailById(orderId: string) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/order_detail?order_id=${orderId}`,
            },
            environment.shopApiService
        );
    }

    getShopList(): Promise<ShopItem[]> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/dispatcher/shop',
            },
            environment.shopApiService
        );
    }

    putApplicationAcceptList(id: number) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: `/am/shops/${id}/status/live`,
            },
            environment.campaignService
        );
    }

    putApplicationSkipList(id: number) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: `/am/shops/${id}/status/rejected`,
            },
            environment.campaignService
        );
    }

    getApplicationAppliedList() {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/shops/applied',
            },
            environment.campaignService
        );
    }

    getApplicationRejectedList() {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/shops/rejected',
            },
            environment.campaignService
        );
    }

    getApplicationAcceptList() {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/shops/live',
            },
            environment.campaignService
        );
    }

    getBatchInfluencerProfilePromise(influencer_list: string[], platform: string = 'instagram', is_simple: boolean = false) {
        return this.requestService.sendRequest<{ [key: string]: any }>(
            {
                method: 'POST',
                url: `/am/modash/batch_profile?platform=${platform}${is_simple ? '&simple_profile=true' : ''}`,
                data: { influencer_list },
            },
            environment.shopApiService
        );
    }

    createNewStore(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/create_shop',
                data,
            },
            environment.shopApiService
        );
    }

    bindShopifyStore(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/bind_shopify_shop',
                data,
            },
            environment.shopApiService
        );
    }

    createInvCode() {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/invitation_code',
                data: {},
            },
            environment.shopApiService
        );
    }

    earningsSummary(data: { report_date: string }) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/earnings_summary',
                data,
            },
            environment.shopApiService
        );
    }

    earningsConfirm(data: { summary_id: number }) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/earnings_confirm',
                data,
            },
            environment.shopApiService
        );
    }

    createFaq(data: FaqToBeUpdated) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/faq',
                data,
            },
            environment.campaignService
        );
    }

    getFaqAll() {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: '/common/faq',
            },
            environment.campaignService
        );
    }

    updateFaq(data: FaqToBeUpdated, id) {
        return this.requestService.sendRequest<any>(
            {
                method: 'PUT',
                url: `/am/faq/${id}`,
                data,
            },
            environment.campaignService
        );
    }

    deleteFaqs(id) {
        return this.requestService.sendRequest<any>(
            {
                method: 'DELETE',
                url: `/am/faq/${id}`,
            },
            environment.campaignService
        );
    }

    earningsSend(data: { summary_id: number }) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/earnings_send',
                data,
            },
            environment.shopApiService
        );
    }

    visitorAnalysis(data: { startDate: string; endDate: string }) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/analytics/visitor_analysis',
                data,
            },
            environment.dataApiService
        );
    }

    publishStoreFront(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/publish_storefront',
                data,
            },
            environment.shopApiService
        );
    }

    getShopDetailById(shopId: any): Promise<ShopSettingsDetail> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/shop_detail?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    getAllStoreLabel(shop_id) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/label/shop?shop_id=${shop_id}`,
            },
            environment.shopApiService
        );
    }

    // label
    getLabelList() {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: '/am/label/label',
            },
            environment.shopApiService
        );
    }

    addLabelList(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/label/list',
                data,
            },
            environment.shopApiService
        );
    }

    addLabelInfluencer(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/label/social-account',
                data,
            },
            environment.shopApiService
        );
    }

    addLabelItemShop(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/label/shop',
                data,
            },
            environment.shopApiService
        );
    }

    addLabelCampaign(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/label/campaign',
                data,
            },
            environment.shopApiService
        );
    }

    getLabelAllList(id) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/label/shop-product?shop=lifo-store&product_id=${id}`,
            },
            environment.shopApiService
        );
    }

    uploadShopItem(shop_item) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/upload_product',
                data: shop_item,
            },
            environment.shopApiService
        );
    }

    offloadShopItem(product_id) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/offload_product',
                data: { product_id },
            },
            environment.shopApiService
        );
    }

    addLabelItem(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/label/label',
                data,
            },
            environment.shopApiService
        );
    }

    addLabelShop(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/label/shop',
                data,
            },
            environment.shopApiService
        );
    }

    updateShopPackageInfo(data: { shop_id: any; shop_packages: { package_type: ProductTypeEnum; package_product_sku: string }[] }) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/shop_package',
                data,
            },
            environment.shopApiService
        );
    }

    updateInventoryById(data: { inventory_id: string; inventory: number }) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/update_product_inventory',
                data,
            },
            environment.shopApiService
        );
    }

    updateShopDetail(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/shop_detail',
                data,
            },
            environment.shopApiService
        );
    }

    getProductPurchase() {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: '/am/dispatcher/product_purchase',
            },
            environment.shopApiService
        );
    }

    postProductPurchaseInbound(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/product_purchase_inbound',
                data,
            },
            environment.shopApiService
        );
    }

    getProductPurchaseInbound() {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: '/am/dispatcher/product_purchase_inbound',
            },
            environment.shopApiService
        );
    }

    createProductPurchase(productPurchase) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/product_purchase',
                data: productPurchase,
            },
            environment.shopApiService
        );
    }

    getRestockRequest(id = null) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/restock_request?shop_id=${id}`,
            },
            environment.shopApiService
        );
    }

    createRestockRequest(massOrdering) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/restock_request',
                data: massOrdering,
            },
            environment.shopApiService
        );
    }

    updateRestockRequest(massOrdering) {
        return this.requestService.sendRequest<any>(
            {
                method: 'PUT',
                url: '/am/dispatcher/restock_request',
                data: massOrdering,
            },
            environment.shopApiService
        );
    }

    getMassOrdering() {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: '/am/dispatcher/mass_ordering',
            },
            environment.shopApiService
        );
    }

    createMassOrdering(massOrdering) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/mass_ordering',
                data: massOrdering,
            },
            environment.shopApiService
        );
    }

    updateMassOrdering(massOrdering) {
        return this.requestService.sendRequest<any>(
            {
                method: 'PUT',
                url: '/am/dispatcher/mass_ordering',
                data: massOrdering,
            },
            environment.shopApiService
        );
    }

    addProduct(productDetail: any) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/create_product',
                data: productDetail,
            },
            environment.shopApiService
        );
    }

    editPurchaseProductInfo(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/product_purchase',
                data,
            },
            environment.shopApiService
        );
    }

    getSummaryCounter(result) {
        let total_purchased = 0;
        let total_sold = 0;
        let sf_in_transit = 0;
        let sf_total_inventory = 0;
        let sf_total_sold = 0;
        let sf_total_fulfilled = 0;
        let hz_in_transit = 0;
        let hz_total_inventory = 0;
        let hz_total_sold = 0;
        let hz_total_fulfilled = 0;

        result.product_purchases.forEach(purchase => {
            total_purchased += purchase.quantity;
            if (purchase.destination_address === 'HZ Warehouse') {
                hz_total_inventory += purchase.arrived_quantity;
                hz_in_transit += purchase.purchased_quantity - purchase.arrived_quantity;
            }
        });

        result.mass_orderings.forEach(ordering => {
            if (!ordering.product_received && ordering.warehouse_name === 'SF Warehouse') {
                sf_in_transit += ordering.quantity;
            }
            if (ordering.product_received && ordering.warehouse_name === 'SF Warehouse') {
                sf_total_inventory += ordering.quantity;
            }
        });

        result.orders.forEach(order => {
            if (order.order_status !== 'canceled') {
                total_sold += order.quantity;
                if (order.warehouse_name === 'SF Warehouse') {
                    sf_total_sold += order.quantity;
                    if (order.fulfillment_status === 'fulfilled') {
                        sf_total_fulfilled += order.quantity;
                    }
                }
                if (order.warehouse_name === 'HZ Warehouse') {
                    hz_total_sold += order.quantity;
                    if (order.fulfillment_status === 'fulfilled') {
                        hz_total_fulfilled += order.quantity;
                    }
                }
            }
        });

        result.inventory_adjustments.forEach(adjustment => {
            if (adjustment.warehouse_name === 'SF Warehouse') {
                sf_total_inventory += adjustment.adjustment;
            }
            if (adjustment.warehouse_name === 'HZ Warehouse') {
                hz_total_inventory += adjustment.adjustment;
            }
        });

        return {
            total_purchased,
            sf_in_transit,
            sf_total_inventory,
            sf_total_sold,
            sf_total_fulfilled,
            total_sold,
            hz_in_transit,
            hz_total_inventory,
            hz_total_sold,
            hz_total_fulfilled,
        };
    }

    getInventoryHistory(sku) {
        return this.requestService
            .sendRequest<any>(
                {
                    method: 'GET',
                    url: `/am/dispatcher/inventory_history?sku=${sku}`,
                },
                environment.shopApiService
            )
            .then(result => {
                result.summary_counter = this.getSummaryCounter(result);
                return Promise.resolve(result);
            });
    }

    addInventoryAdjustment(inventoryAdjustment) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/inventory_adjustment',
                data: inventoryAdjustment,
            },
            environment.shopApiService
        );
    }

    acceptMassOrdering(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/accept_mass_ordering',
                data,
            },
            environment.shopApiService
        );
    }

    addProductRequest(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/create_product_request',
                data,
            },
            environment.shopApiService
        );
    }

    getUnpublishedProducts(id = null) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/unpublished_product?shop_id=${id}`,
            },
            environment.shopApiService
        );
    }

    getSalesData(shopId) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/sales-report-all?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    getProductData(shopId) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/sales-report?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    optionsHandler(options: ProductOption[]) {
        const allOptions = options.map(item => item.values);
        return allOptions.reduce(
            (a, b) => {
                const arr = [];
                a.forEach(itemA => {
                    b.forEach(itemB => {
                        arr.push([...itemA, itemB]);
                    });
                });
                return arr;
            },
            [[]]
        );
    }

    importShipstationCost(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/import_shipping_cost',
                data,
                timeout: 1000 * 3 * 60,
            },
            environment.shopApiService
        );
    }

    getImportShipstationCostHistory() {
        return this.requestService.sendRequest<IImportHistoryElement[]>(
            {
                method: 'GET',
                url: '/am/dispatcher/import_shipping_cost_history',
            },
            environment.shopApiService
        );
    }

    getSkuCostReport(shopId) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/cost_per_sku?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    getSalesReport(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/sales_report',
                data,
            },
            environment.dataApiService
        );
    }

    getCostReport(shopId, startDate, endDate) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/cost_report?shop_id=${shopId}&start=${startDate}&end=${endDate}`,
            },
            environment.shopApiService
        );
    }

    getSpendingReport(shopId, startDate, endDate) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/spendings_report?shop_id=${shopId}&start=${startDate}&end=${endDate}`,
            },
            environment.shopApiService
        );
    }

    getSpecialOrderReport(shopId, startDate, endDate) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/special_order_report?shop_id=${shopId}&start=${startDate}&end=${endDate}`,
            },
            environment.shopApiService
        );
    }

    getLifoRevenue(startDate, endDate) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/revenue_report?start=${startDate}&end=${endDate}`,
            },
            environment.shopApiService
        );
    }

    getTaxReportData(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/tax_report',
                data,
            },
            environment.dataApiService
        );
    }

    getSalesReportData(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/order_report',
                data,
            },
            environment.dataApiService
        );
    }

    getShopSalesReportData(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/shop_sales_report',
                data,
            },
            environment.dataApiService
        );
    }

    getInventoryReportData(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/shop_report',
                data,
                timeout: 1000 * 60 * 30,
            },
            environment.dataApiService
        );
    }

    getStorageBySku(sku: string) {
        return this.requestService.sendRequest<{ quantity: number; name: string; storage_location_id: number }[]>(
            {
                method: 'GET',
                url: `/am/dispatcher/sku_storage_location?sku=${sku}`,
            },
            environment.shopApiService
        );
    }

    getSkuInfoBatch(skuList: string[]) {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'POST',
                url: '/am/dispatcher/sku_report',
                data: { skuList },
            },
            environment.dataApiService
        );
    }

    getShippingScheduleByWarehouse(name: string) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/warehouse_stock?name=${name}`,
            },
            environment.shopApiService
        );
    }

    createShippingSchedule(warehouse_name) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/shipping_schedule',
                data: {
                    warehouse_name,
                },
            },
            environment.shopApiService
        );
    }

    getInventory(skuList: string[]) {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'POST',
                url: '/am/dispatcher/get_product_inventory',
                data: { skuList },
            },
            environment.shopApiService
        );
    }

    async parseInventoryAdjustmentRecordData(file: File): Promise<{
        data: inventoryAdjustmentToBeSaved[];
        errors: string[];
    }> {
        /**
         * File Column
         * sku, adjustment
         */
        const res: inventoryAdjustmentToBeSaved[] = [];
        const workbook = new Excel.Workbook();
        const worksheet = await workbook.xlsx.load(await file.arrayBuffer());
        worksheet.eachSheet(sheet => {
            sheet.eachRow((row, rowNumber) => {
                if (rowNumber !== 1) {
                    const [sku, adjustmentProto] = (row.values as any[]).slice(1);
                    const adjustment = Number(adjustmentProto);
                    const itemToBeAppend: inventoryAdjustmentToBeSaved = {
                        sku,
                        adjustment,
                    };

                    res.push(itemToBeAppend);
                }
            });
        });
        const errors = this.validateInventoryAdjustmentData(res);

        return {
            data: res,
            errors,
        };
    }

    importInventoryAdjustment(data, warehouse_id) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/import_inventory_adjustment',
                data: { inventory_adjustment: data, warehouse_id },
                timeout: 1000 * 60 * 3,
            },
            environment.shopApiService
        );
    }

    getOutOfStockSku(warehouse) {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'GET',
                url: `/am/dispatcher/out_of_stock_sku?warehouse=${warehouse}`,
            },
            environment.shopApiService
        );
    }

    getPendingOrderBySku(sku) {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'GET',
                url: `/am/dispatcher/pending_order_by_sku?sku=${sku}`,
            },
            environment.shopApiService
        );
    }

    getPendingOrder() {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'GET',
                url: '/am/dispatcher/pending_orders',
            },
            environment.shopApiService
        );
    }

    getCustomInfo(orderId) {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'GET',
                url: `/am/dispatcher/get_advanced_customization_order?order_id=${orderId}`,
            },
            environment.shopApiService
        );
    }

    sampleRequestNote(data: { id: number; note: string }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/sample_request_note',
                data,
            },
            environment.shopApiService
        );
    }

    getModashProfile(data: { platform: string; userId: string }) {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'GET',
                url: `/am/modash/profile?platform=${data.platform}&userId=${data.userId}&simple_profile=true`,
            },
            environment.shopApiService
        );
    }

    getUnfulfilledOrders() {
        return this.requestService.sendRequest<any[]>(
            {
                method: 'GET',
                url: '/am/dispatcher/unfulfilled_orders',
            },
            environment.shopApiService
        );
    }

    updateOrderType(data: ExceptionOrder) {
        return this.requestService.sendRequest<any>(
            {
                method: 'PUT',
                url: '/am/dispatcher/update_order_type',
                data,
            },
            environment.shopApiService
        );
    }

    manualExceptionOrder(data: ExceptionOrder) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/manual_exception_order',
                data,
            },
            environment.shopApiService
        );
    }

    private validateInventoryAdjustmentData(data: inventoryAdjustmentToBeSaved[]): string[] {
        const errors = [];
        data.forEach((item, i) => {
            const rowNumber = i + 2;
            // sku id
            if (!item.sku) {
                errors.push(`行${rowNumber}, SKU ID 缺失`);
            }

            // adjustment
            if (!item.adjustment || typeof item.adjustment !== 'number' || !Number.isInteger(item.adjustment)) {
                errors.push(`行${rowNumber}, 调整数量有误或缺失`);
            }
        });
        return errors;
    }
}
