/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { TranslateModule } from '@ngx-translate/core';
import { AntdImportModule } from './antd-import.module';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';

// Components / Directives / Pipes
import { componentArr, directiveArr, pipeArr } from './import.const';

import { AngularCropperjsModule } from 'angular-cropperjs';

@NgModule({
    declarations: [...directiveArr, ...componentArr, ...pipeArr],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        AntdImportModule,
        RichTextEditorModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AngularCropperjsModule,
    ],
    exports: [
        RouterModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AntdImportModule,
        RichTextEditorModule,

        ...componentArr,
        ...directiveArr,
        ...pipeArr,
    ],
})
export class SharedModule {}
