import { Injectable } from '@angular/core';
import { CARRIER_LIST } from '@shared/const/order-dispatch.const';
import { RequestService } from '@src/app/services/request.service';
import * as Excel from 'exceljs';
import { environment } from '@src/environments/environment';

interface OrderTrackingToBeSave {
    warehouse_order_id: any;
    carrier: string;
    tracking_number: string;
    tracking_url: string;
}

export interface ShippingDetail {
    carrier: string;
    details: {
        current: string;
        status: string;
        date: string;
        location: string;
    }[];
    track_number: any;
}

@Injectable({
    providedIn: 'root',
})
export class OrderListService {
    constructor(private requestService: RequestService) {}

    async parseOrderTrackingRecordData(file: File): Promise<{
        data: OrderTrackingToBeSave[];
        errors: string[];
    }> {
        /**
         * File Column
         * warehouse_order_id, carrier, tracking_number, tracking_url
         */
        const res: OrderTrackingToBeSave[] = [];
        const workbook = new Excel.Workbook();
        const worksheet = await workbook.xlsx.load(await file.arrayBuffer());
        worksheet.eachSheet(sheet => {
            sheet.eachRow((row, rowNumber) => {
                if (rowNumber !== 1) {
                    let tracking_url;
                    const [warehouse_order_id, carrier, tracking_number, tracking_url_hyper] = (row.values as any[]).slice(1);
                    if (tracking_url_hyper) {
                        tracking_url = typeof tracking_url_hyper === 'string' ? tracking_url_hyper : tracking_url_hyper.hyperlink;
                    } else {
                        tracking_url = '';
                    }
                    const itemToBeAppend: OrderTrackingToBeSave = {
                        warehouse_order_id,
                        carrier,
                        tracking_number,
                        tracking_url,
                    };

                    res.push(itemToBeAppend);
                }
            });
        });
        const errors = this.validateOrderTrackingData(res);

        return {
            data: res,
            errors,
        };
    }

    importOrderTracking(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/import_tracking_info',
                data,
                timeout: 1000 * 60 * 3,
            },
            environment.shopApiService
        );
    }

    exportOrders(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/export_orders',
                data,
                timeout: 1000 * 60 * 3,
            },
            environment.shopApiService
        );
    }

    getLogisticsByOrderId(orderId: any) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/shared/order/tracking_search?orderId=${orderId}`,
            },
            environment.dataApiService
        );
    }

    private validateOrderTrackingData(data: OrderTrackingToBeSave[]): string[] {
        const errors = [];
        data.forEach((item, i) => {
            const rowNumber = i + 2;
            // tracking_number
            if (!item.tracking_number) {
                errors.push(`行${rowNumber}, Warehouse Order ID 缺失`);
            }

            // carrier
            if (!item.carrier) {
                errors.push(`行${rowNumber}, Carrier 物流公司 缺失`);
            }

            // tracking number
            if (!item.tracking_number) {
                errors.push(`行${rowNumber}, Tracking Number 缺失`);
            }
        });
        return errors;
    }
}
