<nz-filter-trigger [(nzVisible)]="searchVisible" [nzActive]="searchValue?.length > 0" [nzDropdownMenu]="menu">
    <i nz-icon nzType="search"></i>
</nz-filter-trigger>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
        <div class="search-box">
            <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Search</button>
            <button nz-button nzSize="small" (click)="resetTable('searchValue')">Reset</button>
        </div>
    </div>
</nz-dropdown-menu>
