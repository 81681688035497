<section class="content-box">
    <section class="btn-operations">
        <span class="main-content-title" *ngIf="permissionLogistics">{{ 'PRODUCT_LOGISTICS' | translate }}</span>
        <span class="main-content-title" *ngIf="!permissionLogistics">{{ 'TRANSFER_LOGISTICS' | translate }}</span>
        <button
            nz-button
            nzType="primary"
            *ngIf="permissionLogistics"
            [disabled]="!massOrderingList"
            [nzLoading]="isExporting"
            (click)="export()"
        >
            {{'EXPORT' | translate}}
        </button>
    </section>

    <div class="excel-box" *ngIf="permissionLogistics">
        <a [href]="shippingCostExcelTemplateDownloadUrl" download>
            <button style="text-decoration: underline" nz-button nzType="link" class="mr-20">
                <i nz-icon nzType="cloud-download" nzTheme="outline"></i>
                <span class="text">Download "Shipping Cost Import Template" 下载 “运费记录导入模板”</span>
            </button>
        </a>

        <nz-upload
            [nzMultiple]="false"
            [nzShowUploadList]="false"
            [nzBeforeUpload]="beforeUploadShippingCostExcel"
            [nzAccept]="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
        >
            <button style="text-decoration: underline" nz-button nzType="link">
                <i nz-icon nzType="import" nzTheme="outline"></i>
                <span class="text">{{ 'IMPORT_SHIPPING_COST' | translate }}</span>
            </button>
        </nz-upload>

        <div *ngIf="shippingCostDataFile?.file" class="uploaded-file pl-20">
            <div class="file-name">
                <span>{{ shippingCostDataFile?.file?.name }}</span>
            </div>

            <div *ngIf="uploadingShippingCost; else uploaded">{{'FILE_UPLOADING' | translate}}</div>
            <ng-template #uploaded>
                <div *ngIf="shippingCostDataFile?.errors.length === 0" class="status success">{{ 'FILE_UPLOADED' | translate }}</div>
                <div *ngIf="shippingCostDataFile?.errors.length > 0" class="status error">{{ 'FILE_FAILED' | translate }}</div>
            </ng-template>
            <i class="close-icon" nz-icon nzType="close-circle" nzTheme="outline" (click)="shippingCostDataFile = null"></i>
        </div>

        <section class="date-times">
            <nz-range-picker
                [nzFormat]="dateFormat"
                [(ngModel)]="dateTimes"
                (ngModelChange)="onChangeDateTimes(getTitleList(onChangeTitle))"
            ></nz-range-picker>
        </section>
    </div>

    <nz-divider></nz-divider>

    <section class="filter-status">
        <div class="uncomplete" [ngClass]="{ select: onChangeTitle === 'unConfirm' }" (click)="selectStatus('unConfirm')">
            {{ 'UNCONFIRMED' | translate }}
            <span>{{ unConfirmLength }}</span>
        </div>
        <div class="complete" [ngClass]="{ select: onChangeTitle === 'isConfirm' }" (click)="selectStatus('isConfirm')">
            {{ 'CONFIRMED' | translate }}
        </div>
        <div class="complete" [ngClass]="{ select: onChangeTitle === 'isLegacy' }" (click)="selectStatus('isLegacy')">
            {{ 'LEGACY_DATA' | translate }}
            <span>{{ legacyLength }}</span>
        </div>
    </section>

    <section class="data-table">
        <nz-table
            #rowSelectionTable
            nzShowPagination
            nzShowSizeChanger
            [nzLoading]="fetchingMassOrderingList"
            [nzData]="massOrderingList"
            [nzShowPagination]="true"
            [nzScroll]="{ x: '2450px' }"
        >
            <thead>
                <tr>
                    <ng-container *ngFor="let column of columnList">
                        <th
                            *ngIf="!column.show_search"
                            [nzSortFn]="column.sortFn"
                            [nzSortOrder]="onChangeTitle === 'isLegacy'?column.defaultSortDate:column.defaultSort"
                            [nzWidth]="column.width"
                            [nzFilters]="column.listOfFilter"
                            [nzFilterFn]="column.filterFn"
                            [nzRight]="!!column.nzRight"
                        >
                            {{ column.name | translate }}
                        </th>
                        <th *ngIf="column.show_search" [nzSortFn]="column.sortFn" [nzWidth]="column.width">
                            {{ column.name | translate }}
                            <app-table-search-dropdown
                                [defaultProductList]="defaultOrderingList"
                                [name]="column.id"
                                (searchList)="searchResultList($event)"
                                (resetList)="resetResultList()"
                            ></app-table-search-dropdown>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of rowSelectionTable.data">
                    <td>{{ data.box_id }}</td>
                    <td>
                        <img src="{{ data.variant_image }}" style="height: 40px; width: 40px" />
                    </td>
                    <td>
                        <div>{{ data.variant_name }}</div>
                        <div>{{ data.product_sku }}</div>
                    </td>
                    <td>{{ data.shop_name }}</td>
                    <td>
                        <div>{{ data.product_name }}</div>
                        <div>{{ data.product_id }}</div>
                    </td>
                    <td>{{ data.warehouse_name }}</td>
                    <td>{{ data.shipped_quantity }} / {{ data.arrived_quantity }}</td>
                    <td>{{ data.shipping_method ? data.shipping_method : '待回填' }}</td>
                    <td>{{ data.carrier ? data.carrier : '待回填' }} / {{ data.tracking_number }}</td>
                    <td>{{ data.shipping_cost | currency: data.currency }}</td>
                    <td>
                        <ng-container *ngIf="data.logistics_type === 'us'"> {{'US_PURCHASE' | translate}} </ng-container>
                        <ng-container *ngIf="data.logistics_type !== 'us'"> {{'INTERNATIONAL_LOGISTICS' | translate}} </ng-container>
                    </td>
                    <td [ngStyle]="{'color': setColor(data)}">{{ data.shipping_date | date: 'MM/dd/yyyy' }}</td>
                    <td [ngStyle]="{'color': setColor(data)}">{{ data.deliver_eta | date: 'MM/dd/yyyy' }}</td>
                    <td>
                        <ng-container [ngSwitch]="data.trackingStatusCode">
                            <span *ngSwitchCase="1" class="tracking-status in-transit">运输中</span>
                            <span *ngSwitchCase="2" class="tracking-status arrived-unconfirmed">已到达（48小时内）</span>
                            <span *ngSwitchCase="3" class="tracking-status arrived-unconfirmed-48">已到达（超48小时）</span>
                            <span *ngSwitchCase="4" class="tracking-status arrived-confirmed">已送达</span>
                        </ng-container>
                        <i
                            class="tracking-icon"
                            nz-icon
                            nzType="eye"
                            nzTheme="fill"
                            (click)="viewTrackingDetail(data.latest_shipping_status)"
                        ></i>
                    </td>
                    <td>{{ data.restock_request_id || '---' }}</td>
                    <td>{{ data.product_purchase_id || '---' }}</td>
                    <td [nzRight]="true">
                        <div class="checkpoint-icon">
                            <i class="check-icon" nz-icon nzType="check-circle" nzTheme="fill" *ngIf="data.product_received"></i>
                            <ng-container *ngIf="permissionService.currentUserPermission.isProductLogisticsFullEdit; else closeIcon">
                                <button nz-button nzType="primary" *ngIf="!data.product_received" (click)="showEditQuantityModal(data)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                                    <span>{{'ACCEPT' | translate}}</span>
                                </button>
                            </ng-container>
                            <ng-template #closeIcon>
                                <i *ngIf="!data.product_received" nz-icon nzType="close-circle" nzTheme="fill" style="color: red"></i>
                            </ng-template>
                            <button
                                nz-button
                                nzType="primary"
                                *ngIf="!permissionService.currentUserPermission.isProductLogisticsReadOnly && permissionLogistics"
                                (click)="showEditMassOrderModal(data)"
                                style="margin-left: 10px"
                            >
                                <i nz-icon nzType="edit" nzTheme="outline"></i>
                                <span>{{'EDIT' | translate}}</span>
                            </button>
                            <button
                                *ngIf="data.product_received && data.note"
                                nz-button
                                nzType="text"
                                (click)="viewConfirmNote(data)"
                                style="padding: 4px 5px; margin-left: 15px"
                            >
                                <span style="color: #414bb2">备注</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </section>
</section>

<nz-modal [(nzVisible)]="showTrackingDetail" nzTitle="Tracking Detail" (nzOnCancel)="closeTrackingDetail()" [nzFooter]="null">
    <table>
        <tr *ngFor="let detail of trackingDetail?.tracking_details">
            <td style="width: 80px">{{ detail.datetime | date: 'MM/dd HH:mm' }}</td>
            <td>{{ detail.message }}</td>
            <td style="width: 150px">{{ detail.tracking_location?.city }}, {{ detail.tracking_location?.country }}</td>
        </tr>
    </table>
</nz-modal>

<nz-modal
    nzTitle="Accept Delivery"
    [nzOkDisabled]="acceptMassOrderingDisabled"
    [nzOkText]="'Confirm'"
    [nzOkLoading]="acceptMassOrderingSubmitting"
    [(nzVisible)]="acceptMassOrderingModalVisible"
    (nzOnCancel)="acceptMassOrderingModalVisible = false"
    (nzOnOk)="acceptMassOrdering()"
>
    <div class="edit-quantity-box">
        <div class="quantity-item" style="margin-bottom: 20px">
            <div class="title">Shipped Quantity</div>
            <input #quantityInput disabled [value]="selectedRecordItem.shipped_quantity" nz-input style="width: 100%" />
        </div>

        <div class="quantity-item" style="margin-bottom: 20px">
            <div class="title">Arrived Quantity</div>
            <input #arrivedQuantityInput [(ngModel)]="selectedRecordItem.arrived_quantity" nz-input type="number" style="width: 100%" />
        </div>

        <div class="quantity-item" style="margin-bottom: 20px">
            <div class="title">Add to Storage Zone</div>
            <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Select Zone"
                style="width: 100%"
                [(ngModel)]="selectedRecordItem.storage_location_id"
            >
                <nz-option
                    *ngFor="let storageItem of selectedRecordItem.storageOfCurrentSku"
                    nzCustomContent
                    [nzLabel]="storageItem.name"
                    [nzValue]="storageItem.storage_location_id"
                >
                    <div class="d-flex justify-content-between align-items-center">
                        <span>{{ storageItem.name }}</span>
                        <span [class.fw-700]="storageItem.quantity > 0">Existing Qty: {{ storageItem.quantity || 0 }}</span>
                    </div>
                </nz-option>
            </nz-select>
        </div>

        <div style="margin-bottom: 20px">
            <label nz-checkbox [(ngModel)]="selectedRecordItem.product_received">手动收货完成</label>
        </div>
        <div [hidden]="isShowConfirmNote" style="margin-bottom: 10px">
            <div>备注</div>
            <textarea nz-input [(ngModel)]="selectedRecordItem.note" [nzAutosize]="{ minRows: 3, maxRows: 3 }"></textarea>
        </div>
    </div>
</nz-modal>

<nz-modal
    nzTitle="{{ 'EDIT_PRODUCT_LOGISTICS_INFORMATION' | translate }}"
    [nzOkDisabled]="editMassOrderingDisabled"
    [nzOkText]="'Confirm'"
    [nzOkLoading]="editMassOrderingSubmitting"
    [(nzVisible)]="editMassOrderingModalVisible"
    (nzOnCancel)="editMassOrderingModalVisible = false"
    (nzOnOk)="editMassOrdering()"
>
    <section class="edit-info">
        <ng-container *ngIf="!selectedMassOrder.product_received; else editAfterReceived">
            <div class="form-item">
                <div class="inline-form-item">
                    <div class="title">{{ 'DESTINATION_WAREHOUSE' | translate }} <span class="required">*</span></div>
                    <div class="inline-box destination-warehouse">
                        <nz-select [(ngModel)]="selectedMassOrder.warehouse_id" [nzPlaceHolder]="'Destination Warehouse'">
                            <nz-option
                                *ngFor="let warehouse of warehouseList"
                                [nzValue]="warehouse.warehouse_id"
                                [nzLabel]="warehouse.warehouse_name"
                            ></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div class="inline-form-item">
                    <div class="title">{{ 'SHIPPED_QUANTITY' | translate }} <span class="required">*</span></div>
                    <div class="inline-box">
                        <input nz-input type="number" min="0" [(ngModel)]="selectedMassOrder.shipped_quantity" />
                    </div>
                </div>
            </div>
            <div class="form-item">
                <div class="inline-form-item">
                    <div class="title">{{ 'CARRIER' | translate }}</div>
                    <div class="inline-box">
                        <nz-select [(ngModel)]="selectedMassOrder.carrier" [nzPlaceHolder]="'Carrier'" [nzShowSearch]="true">
                            <nz-option
                                *ngFor="let carrier of (selectedMassOrder.logistics_type === 'us' ? CARRIER_LIST_US : CARRIER_LIST)"
                                [nzValue]="carrier"
                                [nzLabel]="carrier"
                            ></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div class="inline-form-item" *ngIf="selectedMassOrder.carrier !== '自取'">
                    <div class="title">{{ 'SHIPPING_NUMBER' | translate }}</div>
                    <div class="inline-box">
                        <input nz-input [(ngModel)]="selectedMassOrder.tracking_number" placeholder="Tracking Number" />
                    </div>
                </div>
                <div class="inline-form-item" *ngIf="selectedMassOrder.carrier === '自取'">
                    <div class="title">{{ 'SHIPPING_COST' | translate }}</div>
                    <div class="inline-box">
                        <nz-input-group nzCompact>
                            <nz-select [(ngModel)]="selectedMassOrder.currency" style="width: 60px">
                                <nz-option nzValue="USD" nzLabel="$"></nz-option>
                                <nz-option nzValue="CNY" nzLabel="¥"></nz-option>
                            </nz-select>
                            <input
                                nz-input
                                type="number"
                                min="0"
                                [(ngModel)]="selectedMassOrder.shipping_cost"
                                style="width: calc(100% - 60px)"
                            />
                        </nz-input-group>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <div class="inline-form-item" *ngIf="selectedMassOrder.logistics_type === 'international'">
                    <div class="title">{{ 'SHIPPING_METHOD' | translate }}</div>
                    <div class="inline-box">
                        <nz-select
                            [(ngModel)]="selectedMassOrder.shipping_method"
                            [nzPlaceHolder]="'Shipping method'"
                            [nzShowSearch]="true"
                        >
                            <nz-option
                                *ngFor="let shippingMethod of SHIPPING_METHOD_LIST"
                                [nzValue]="shippingMethod"
                                [nzLabel]="shippingMethod"
                            ></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div class="inline-form-item" *ngIf="selectedMassOrder.carrier !== '自取'">
                    <div class="title">{{ 'SHIPPING_COST' | translate }}</div>
                    <div class="inline-box">
                        <nz-input-group nzCompact>
                            <nz-select [(ngModel)]="selectedMassOrder.currency" style="width: 60px">
                                <nz-option nzValue="USD" nzLabel="$"></nz-option>
                                <nz-option nzValue="CNY" nzLabel="¥"></nz-option>
                            </nz-select>
                            <input
                                nz-input
                                type="number"
                                min="0"
                                [(ngModel)]="selectedMassOrder.shipping_cost"
                                style="width: calc(100% - 60px)"
                            />
                        </nz-input-group>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <div class="inline-form-item">
                    <div class="title">{{ 'SHIPPING_DATE' | translate }} <span class="required">*</span></div>
                    <div class="inline-box">
                        <nz-date-picker
                            nzFormat="MM/dd/yyyy"
                            nzPlaceHolder="MM/DD/YYYY"
                            [(ngModel)]="selectedMassOrder.shipping_date"
                        ></nz-date-picker>
                    </div>
                </div>
                <div class="inline-form-item">
                    <div class="title">{{ 'DELIVER_ETA' | translate }} <span class="required">*</span></div>
                    <div class="inline-box">
                        <nz-date-picker
                            nzFormat="MM/dd/yyyy"
                            nzPlaceHolder="MM/DD/YYYY"
                            [(ngModel)]="selectedMassOrder.deliver_eta"
                        ></nz-date-picker>
                    </div>
                </div>
            </div>
            <div class="form-item" *ngIf="selectedMassOrder.logistics_type !== 'us'">
                <div class="inline-form-item">
                    <div class="title">{{ 'SHIPPING_WAREHOUSE' | translate }} <span class="required">*</span></div>
                    <div class="inline-box destination-warehouse">
                        <nz-select [(ngModel)]="selectedMassOrder.shipping_warehouse" [nzPlaceHolder]="'Shipping Warehouse'">
                            <nz-option
                                *ngFor="let warehouse of DESTINATION_CHINA_WAREHOUSE"
                                [nzValue]="warehouse"
                                [nzLabel]="warehouse"
                            ></nz-option>
                        </nz-select>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #editAfterReceived>
            <div class="form-item">
                <div class="inline-form-item">
                    <div class="title">{{ 'SHIPPING_COST' | translate }}</div>
                    <div class="inline-box">
                        <nz-input-group nzCompact>
                            <nz-select [(ngModel)]="selectedMassOrder.currency" style="width: 60px">
                                <nz-option nzValue="USD" nzLabel="$"></nz-option>
                                <nz-option nzValue="CNY" nzLabel="¥"></nz-option>
                            </nz-select>
                            <input
                                nz-input
                                type="number"
                                min="0"
                                [(ngModel)]="selectedMassOrder.shipping_cost"
                                style="width: calc(100% - 60px)"
                            />
                        </nz-input-group>
                    </div>
                </div>
            </div>
        </ng-template>
    </section>
</nz-modal>
<nz-modal
    nzTitle="Print Barcode"
    [(nzVisible)]="printBarcodeModal.visible"
    (nzOnCancel)="printBarcodeModal.hideModal()"
    (nzOnOk)="printBarcode()"
>
    Do you want to print the barcode?
</nz-modal>
