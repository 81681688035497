import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Inject, Injector, ComponentFactoryResolver, ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import zh from '@angular/common/locales/zh';

// Module
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { AuthModule } from '@pages/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

// Component
import { AppComponent } from './app.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { nzConfigFactory } from '@shared/components/antd-global-config/antd-global-config.component';

// Others
import { environment } from '@src/environments/environment';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { ReuseService } from './services/route-reuse.service';
import { GlobalErrorHandler } from './services/global-error-handler';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './services/timeout-http';

registerLocaleData(en);
registerLocaleData(zh);

export const TranslateLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

export const LocaleIdFactory = () => {
    if (localStorage.getItem('langs')) {
        return localStorage.getItem('langs');
    } else {
        const browserLangs = navigator.language.toLowerCase();
        localStorage.setItem('langs', browserLangs);
        return browserLangs;
    }
};
@NgModule({
    declarations: [AppComponent, DashboardComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        AuthModule,
        AngularFireAuthModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AngularFireModule.initializeApp(environment.firebase),
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        { provide: LOCALE_ID, useFactory: LocaleIdFactory },
        { provide: RouteReuseStrategy, useClass: ReuseService },
        { provide: NZ_CONFIG, useFactory: nzConfigFactory, deps: [Injector, ComponentFactoryResolver] },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private i18n: TranslateService, @Inject(LOCALE_ID) locale: string) {
        if (environment.supportedLocale.indexOf(locale) === -1) {
            locale = 'en-us';
        }

        this.i18n.use(locale);
    }
}
