import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { filter } from 'lodash';

@Component({
    selector: 'app-table-search-dropdown',
    templateUrl: './table-search-dropdown.component.html',
    styleUrls: ['./table-search-dropdown.component.less'],
})
export class TableSearchDropdownComponent implements OnInit {
    @Input() defaultProductList;
    @Input() name: any;
    @Output() searchList = new EventEmitter<any>();
    @Output() resetList = new EventEmitter<any>();

    searchValue = '';
    searchVisible = false;

    constructor() {}

    ngOnInit() {}

    resetTable(name) {
        this[name] = '';
        this.resetList.emit();
    }

    search(): void {
        this.searchVisible = false;
        let productList = [];
        if (typeof this.name == 'string') {
            productList = this.defaultProductList
                .filter(productItem => productItem[this.name] !== null && productItem[this.name] !== undefined)
                .filter(productItem => String(productItem[this.name]).toLowerCase().includes(String(this.searchValue).toLowerCase()));
        }
        if (Array.isArray(this.name)) {
            if (this.name.includes('tracking_number') && this.name.includes('carrier')) {
                productList = [
                    ...this.defaultProductList.filter(productItem =>
                        String(productItem.tracking_number).toLowerCase().includes(String(this.searchValue).toLowerCase())
                    ),
                    ...this.defaultProductList.filter(productItem =>
                        String(productItem.carrier).toLowerCase().includes(String(this.searchValue).toLowerCase())
                    ),
                ];
            } else {
                let searchItemList = [];
                let commonSearch = [];
                searchItemList = this.defaultProductList
                    .filter(productItem => productItem.inbound_result_list !== null && productItem.inbound_result_list !== undefined)
                    .filter(productItem =>
                        JSON.stringify(productItem.inbound_result_list).includes(String(this.searchValue).toLowerCase())
                    );

                commonSearch = commonSearch.concat(searchItemList);
                productList = [...commonSearch];
            }
        }

        this.searchList.emit(productList);
    }
}
