import { environment } from '@src/environments/environment';
import { Injectable } from '@angular/core';

import { RequestService } from '@services/request.service';
import { random } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class FirebaseFileUploadService {
    constructor(private requestService: RequestService) {}

    startUpload(file: File, originUploadPath = 'public/images'): Promise<{ path: string; src: string }> {
        const indexOfDot = file.name.lastIndexOf('.');
        const fileType = file.name.slice(indexOfDot);
        const path = `${originUploadPath}/${Date.now()}/${random(0, 100000)}${indexOfDot === -1 ? '' : fileType}`;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);

        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/upload_public_image',
                data: formData,
                timeout: 1000 * 60 * 3,
            },
            environment.shopApiService
        );
    }
}
