import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { cloneDeep } from 'lodash';

interface AddressItem {
    name: string;
    phone: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
}

@Component({
    selector: 'app-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.less'],
})
export class AddressModalComponent implements OnInit {
    @Input() addressDetails: any;
    @Input() type: 'view' | 'add' = 'view';
    @Output() updateAddressSuccess = new EventEmitter();
    @Output() closeModal = new EventEmitter();

    isAddressLoading = false;
    address: AddressItem = {
        name: '',
        phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    };

    get addBtnDisabled() {
        return (
            !this.address.name ||
            !this.address.address_line_1 ||
            !this.address.city ||
            !this.address.state ||
            !this.address.zip ||
            !this.address.country
        );
    }

    constructor(private orderDispatchService: OrderDispatchService) {}

    ngOnInit() {}

    updateAddress() {
        this.isAddressLoading = true;
        const data = cloneDeep(this.address);
        this.orderDispatchService.createAddressBook(data).then(res => {
            this.address = {
                name: '',
                phone: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            };
            this.isAddressLoading = false;
            this.updateAddressSuccess.emit(data);
        });
    }

    closeAddressModal() {
        this.closeModal.emit();
    }
}
