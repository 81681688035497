import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import {
    OUT_OF_STOCK_COLUMN_LIST,
    PENDING_ORDER_COLUMN_LIST,
    EXCEL_COLUMN,
    PER_SHOP_ORDER_COLUMN_LIST,
    ORDER_MONITOR_COLUMN_LIST,
} from './dashboard.data';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { UtilsService } from '@services/util.service';
import {
    ShopItem,
    OutOfStockListItem,
    PendingOrderListItem,
    unfulfilledOrderItem,
    perShopOrderItem,
    ExceptionOrder,
    OrderTypeText,
    InternalOrderStatusText,
    ResolutionList,
    InternalOrderStatusList,
} from '@shared/type/order-dispatch.type';
import { UserPermissionService } from '@src/app/services/user-permission.service';
import { groupBy, map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { OrderListService } from '@pages/order-management/order-list/order-list.service';
import { NotificationService, AlertType } from '@services/notification.service';

export enum LangsEnum {
    'en' = 'en-us',
    'zh' = 'zh-cn',
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
    LangsEnum = LangsEnum;

    currentLang: LangsEnum;

    outOfStockColumnList = OUT_OF_STOCK_COLUMN_LIST;
    pendingOrderColumnList = PENDING_ORDER_COLUMN_LIST;
    perShopOrderColumnList = PER_SHOP_ORDER_COLUMN_LIST;
    orderMonitorColumnList = ORDER_MONITOR_COLUMN_LIST;

    selectedWarehouse = 'HZ Warehouse';
    defaultOutOfStockList: OutOfStockListItem[];
    outOfStockList: OutOfStockListItem[];
    outOfStockLoading = false;
    isDownloadingExcel = false;
    expandSet = new Set<string>();
    pendingOrderBySkuMap: Map<string, PendingOrderListItem[]> = new Map();

    pendingOrderLoading = false;
    pendingOrderList: PendingOrderListItem[];
    defaultPendingOrderList: PendingOrderListItem[];
    allChecked = false;
    indeterminate = false;
    setOfCheckedId = new Set<number>();

    unfulfilledOrderLoading = false;
    defaultUnfulfilledOrders: unfulfilledOrderItem[];
    perShopOrders: perShopOrderItem[];
    unfulfilledOrders$: BehaviorSubject<unfulfilledOrderItem[]> = new BehaviorSubject([]);
    selectedOrderType = '';
    isProcessed = false;
    orderTypes = [
        { name: '全部', count: 0, value: '' },
        { name: '未划分订单', count: 0, value: 'undivided' },
        { name: '常规订单', count: 0, value: 'normal' },
        { name: '预售订单', count: 0, value: 'preorder' },
        { name: '内部测试订单', count: 0, value: 'test' },
    ];
    orderTypeMap = OrderTypeText;
    orderStatusMap = InternalOrderStatusText;
    orderStatusList = InternalOrderStatusList;

    trackingDetailModalVisible = false;
    selectedOrderId;

    orderCreateTime = [];
    allOrderChecked = false;
    setOfCheckedOrderId = new Set<number>();
    exportingOrders = false;
    orderFilterParams: {
        shop_name?: string[];
        warehouse_name?: string[];
        order_type?: string[];
        order_status?: string[];
    } = {};

    unfulfilledOrderModalVisible = false;
    unfulfilledOrderParams: ExceptionOrder = {
        id: null,
        order_type: null,
        exception: {
            exception_status: null,
            exception_note: null,
            resolution_status: null,
            resolution_note: null,
            customer_care: false,
        },
    };
    isEditOrder = false;
    resolutionList = ResolutionList;
    orderSubmitting = false;
    addOrderErrorMsg = null;

    get addUnfulfilledOrderDisabled() {
        return (
            !this.unfulfilledOrderParams.id ||
            !this.unfulfilledOrderParams.exception.exception_status ||
            (this.unfulfilledOrderParams.exception.exception_status === 'other' && !this.unfulfilledOrderParams.exception.exception_note) ||
            !this.unfulfilledOrderParams.exception.resolution_status
        );
    }

    constructor(
        private translateService: TranslateService,
        private orderDispatchService: OrderDispatchService,
        private router: Router,
        private utilService: UtilsService,
        public permissionService: UserPermissionService,
        public orderListService: OrderListService,
        public notificationService: NotificationService
    ) {
        const localLangs = localStorage.getItem('langs') as LangsEnum;
        this.changeLang(localLangs);
    }

    ngOnInit() {
        this.getUnfulfilledOrders();
        this.getOutOfStockSku();
        this.getPendingOrder();
        this.orderDispatchService.getShopList().then((res: ShopItem[]) => {
            const shopList = res.filter(ele => ele.shop_id > 0).map(ele => ({ text: ele.name, value: ele.name }));
            this.outOfStockColumnList[2].listOfFilter = shopList;
            this.pendingOrderColumnList[2].listOfFilter = shopList;
            this.orderMonitorColumnList[2].listOfFilter = shopList;
        });
    }

    changeLang(langs: LangsEnum) {
        if (langs === LangsEnum.en || langs === LangsEnum.zh) {
            this.translateService.setDefaultLang(langs);
            this.translateService.use(langs);
            this.currentLang = langs;
            localStorage.setItem('langs', langs);
        }
    }

    changeWarehouse(warehouse) {
        if (this.outOfStockLoading) return false;
        this.selectedWarehouse = warehouse;
        this.getOutOfStockSku();
    }

    changeUnfulfilledOrders(type: string, status: boolean) {
        const dateFormat = time => dayjs(time).format('YYYY-MM-DD');
        this.selectedOrderType = type;
        this.isProcessed = status;
        this.unfulfilledOrders$.next(
            this.defaultUnfulfilledOrders
                .filter(ele => (!type ? true : ele.order_type === type))
                .filter(ele => !!ele.exception?.resolution_status === status)
                .filter(ele =>
                    this.orderCreateTime.length
                        ? dayjs(dateFormat(this.orderCreateTime[0])).diff(dayjs(dateFormat(ele.created_at)), 'day') <= 0 &&
                          dayjs(dateFormat(this.orderCreateTime[1])).diff(dayjs(dateFormat(ele.created_at)), 'day') >= 0
                        : ele
                )
        );
        this.refreshOrderCheckedStatus();
    }

    onOrderTimeChange() {
        this.changeUnfulfilledOrders(this.selectedOrderType, this.isProcessed);
    }

    getUnfulfilledOrders() {
        this.unfulfilledOrderLoading = true;
        this.orderDispatchService
            .getUnfulfilledOrders()
            .then(res => {
                this.perShopOrders = map(groupBy(res, 'shop_name'), (ele, index) => ({
                    shop_name: index,
                    order_total: ele.length,
                    undivided_order: ele.filter(e => !e.order_type).length,
                    normal_order: ele.filter(e => e.order_type === 'normal').length,
                    preorder_order: ele.filter(e => e.order_type === 'preorder').length,
                    test_order: ele.filter(e => e.order_type === 'test').length,
                }));
                this.defaultUnfulfilledOrders = (res || []).map(ele => ({
                    ...ele,
                    order_type: ele.order_type ? ele.order_type : 'undivided',
                    not_shipped_waiting_time:
                        ele.warehouse_created_at && !ele.fulfillment_created_at
                            ? dayjs(dayjs()).diff(ele.warehouse_created_at, 'hour')
                            : null,
                    custom_waiting_time: dayjs(dayjs()).diff(ele.created_at, 'day'),
                }));
                this.orderTypes.forEach(ele => {
                    ele.count = ele.value
                        ? this.defaultUnfulfilledOrders.filter(e => e.order_type === ele.value).length
                        : this.defaultUnfulfilledOrders.length;
                });
                this.changeUnfulfilledOrders(this.selectedOrderType, this.isProcessed);
                this.orderMonitorColumnList[3].listOfFilter = map(groupBy(res, 'warehouse_name'), (ele, index) => ({
                    text: index,
                    value: index,
                }));
            })
            .finally(() => {
                this.unfulfilledOrderLoading = false;
            });
    }

    getOutOfStockSku() {
        this.outOfStockLoading = true;
        this.orderDispatchService
            .getOutOfStockSku(this.selectedWarehouse)
            .then(res => {
                this.defaultOutOfStockList = res;
                this.outOfStockList = res;
            })
            .finally(() => {
                this.outOfStockLoading = false;
            });
    }

    getPendingOrder() {
        this.pendingOrderLoading = true;
        this.setOfCheckedId.clear();
        const time = `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date()}`;
        this.orderDispatchService
            .getPendingOrder()
            .then(res => {
                res.forEach(ele => {
                    ele.waiting_time = dayjs(time).diff(dayjs(ele.created_at).format('YYYY-MM-DD'), 'day');
                });
                this.pendingOrderList = res;
                this.defaultPendingOrderList = res;
            })
            .finally(() => {
                this.pendingOrderLoading = false;
            });
    }

    outOfStockRefresh() {
        this.getOutOfStockSku();
        this.expandSet.clear();
        this.pendingOrderBySkuMap.clear();
    }

    searchResultUnfulfilledOrder(values) {
        this.unfulfilledOrders$.next(values);
    }

    resetResultUnfulfilledOrder() {
        this.unfulfilledOrders$.next(this.defaultUnfulfilledOrders);
    }

    filterOrderChange(values, column) {
        this.orderFilterParams[column.id] = values;
    }

    searchResultOutOfStockList(values) {
        this.outOfStockList = values;
    }

    resetResultOutOfStockList() {
        this.outOfStockList = this.defaultOutOfStockList;
    }

    goProductDetail(data) {
        window.open(`/product-management/product-detail/${data.product_id}`, '_blank');
    }

    goOrderDetail(data) {
        window.open(`/order-management/order-detail/${data.id}`, '_blank');
    }

    exportOutOfStockSku() {
        this.isDownloadingExcel = true;
        const data = this.defaultOutOfStockList.map(item => ({
            variant_image: item.variant_image,
            sku: `${item.variant_name} / ${item.sku}`,
            product_name: item.product_name,
            unfulfilled_quantity: item.unfulfilled_quantity,
            in_transit_quantity: item.in_transit_quantity,
            in_stock_quantity: item.in_stock_quantity,
            sourcing_link: item.product_sourcing_urls?.map(ele => ele.value).join(', '),
        }));
        return this.utilService
            .exportToExcel({
                header: EXCEL_COLUMN,
                fileName: `${this.selectedWarehouse} 缺货SKU`,
                sheets: [{ sheetName: 'SKU List', data }],
            })
            .then(() => (this.isDownloadingExcel = false));
    }

    async getOrderListBySku(sku) {
        if (!this.pendingOrderBySkuMap.has(sku)) {
            const data = await this.orderDispatchService.getPendingOrderBySku(sku);
            if (data) {
                this.pendingOrderBySkuMap.set(sku, data);
            }
        }
    }

    onExpandChange(sku) {
        if (this.expandSet.has(sku)) {
            this.expandSet.delete(sku);
        } else {
            this.getOrderListBySku(sku);
            this.expandSet.add(sku);
        }
    }

    async batchProcessingByOutOfStock(sku) {
        await this.getOrderListBySku(sku);
        const orderIds = this.pendingOrderBySkuMap.get(sku).map(ele => ele.id);
        this.openOrderList(orderIds);
    }

    searchResultPendingOrder(values) {
        this.pendingOrderList = values;
    }

    resetResultPendingOrder() {
        this.pendingOrderList = this.defaultPendingOrderList;
    }

    updateCheckedSet(id: number, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }

    onAllChecked(checked: boolean): void {
        this.pendingOrderList.forEach(item => this.updateCheckedSet(item.id, checked));
        this.refreshCheckedStatus();
    }

    onItemChecked(id: number, checked: boolean): void {
        this.updateCheckedSet(id, checked);
        this.refreshCheckedStatus();
    }

    refreshCheckedStatus(): void {
        this.allChecked = this.pendingOrderList.every(item => this.setOfCheckedId.has(item.id));
        this.indeterminate = this.pendingOrderList.some(item => this.setOfCheckedId.has(item.id)) && !this.allChecked;
    }

    updateOrderCheckedSet(id: number, checked: boolean): void {
        if (checked) {
            this.setOfCheckedOrderId.add(id);
        } else {
            this.setOfCheckedOrderId.delete(id);
        }
    }

    onAllOrderChecked(checked: boolean): void {
        this.unfulfilledOrders$.value
            .filter(
                item =>
                    !map(this.orderFilterParams, (ele, index) => (!ele?.length || ele.includes(item[index]) ? 'true' : 'false')).includes(
                        'false'
                    )
            )
            .forEach(item => this.updateOrderCheckedSet(item.id, checked));
        this.refreshOrderCheckedStatus();
    }

    onOrderItemChecked(id: number, checked: boolean): void {
        this.updateOrderCheckedSet(id, checked);
        this.refreshOrderCheckedStatus();
    }

    refreshOrderCheckedStatus(): void {
        this.allChecked = this.unfulfilledOrders$.value.every(item => this.setOfCheckedId.has(item.id));
    }

    batchProcessingByPendingOrder() {
        this.openOrderList(Array.from(this.setOfCheckedId));
    }

    openOrderList(selectedOrderList = []) {
        this.orderDispatchService.selectedOrderList = selectedOrderList;
        this.router.navigate(['/order-management/order-list'], { queryParams: { orderSelected: true } });
    }

    getPurchaseCount(data) {
        return Math.max(0, data.unfulfilled_quantity - data.in_stock_quantity - data.in_transit_quantity);
    }

    getResolutionNote(data: unfulfilledOrderItem) {
        return data.resolutions
            ?.filter(ele => ele.resolution_status === data.order_status)
            ?.sort((a, b) => dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf())[0];
    }

    exportOrders() {
        const data = {
            order_ids: Array.from(this.setOfCheckedOrderId),
        };
        this.exportingOrders = true;
        this.orderListService
            .exportOrders(data)
            .then(res => {
                window.open(res.download_url);
            })
            .catch(err => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Export orders Failed',
                    message: err.error.error,
                    duration: 5000,
                });
            })
            .finally(() => {
                this.exportingOrders = false;
            });
    }

    addUnfulfilledOrder() {
        this.orderSubmitting = true;
        const params = { ...this.unfulfilledOrderParams };
        params.order_type = params.order_type === 'undivided' ? null : params.order_type;
        this.orderDispatchService
            .manualExceptionOrder(params)
            .then(res => {
                this.unfulfilledOrderModalVisible = false;
                this.getUnfulfilledOrders();
                this.addOrderErrorMsg = null;
                this.unfulfilledOrderParams = {
                    id: null,
                    order_type: null,
                    exception: {
                        exception_status: null,
                        exception_note: null,
                        resolution_status: null,
                        resolution_note: null,
                        customer_care: false,
                    },
                };
            })
            .catch(err => {
                this.addOrderErrorMsg = err.error?.message;
            })
            .finally(() => {
                this.orderSubmitting = false;
            });
    }
}
