export const OUT_OF_STOCK_COLUMN_LIST = [
    {
        id: 'sku',
        name: 'SKU & Variant',
        show_search: true,
    },
    {
        id: 'product_name',
        name: '商品名',
    },
    {
        id: 'shop_name',
        name: '店铺',
        listOfFilter: [],
        filterFn: (list: string[], item) => list.some(name => item.shop_name.indexOf(name) !== -1),
    },
    {
        id: 'unfulfilled_quantity',
        name: '下单未履约',
    },
    {
        id: 'in_transit_quantity',
        name: '在库/在途',
    },
    {
        id: 'in_stock_quantity',
        name: '应采购数',
    },
    {
        id: 'operation',
        name: '关联缺货订单',
        width: '200px',
    },
];
export const PENDING_ORDER_COLUMN_LIST = [
    {
        id: 'name',
        name: 'Order',
        show_search: true,
    },
    {
        id: 'created_at',
        name: '创建日期',
    },
    {
        id: 'shop_name',
        name: '店铺',
        listOfFilter: [],
        filterFn: (list: string[], item) => list.some(name => item.shop_name.indexOf(name) !== -1),
    },
    {
        id: 'warehouse_name',
        name: '履约仓',
        listOfFilter: [
            { text: 'HZ Warehouse', value: 'HZ Warehouse' },
            { text: 'SF Warehouse', value: 'SF Warehouse' },
        ],
        filterFn: (list: string[], item) => list.some(name => item.warehouse_name.indexOf(name) !== -1),
    },
    {
        id: 'waiting_time',
        name: '等待时长',
        defaultSort: 'descend',
        sortFn: (a, b) => a.waiting_time - b.waiting_time,
    },
];

export const EXCEL_COLUMN = [
    {
        id: 'variant_image',
        name: '',
        width: 8,
        type: 'image',
        imgWidth: 50,
        imgHeight: 50,
    },
    {
        id: 'sku',
        name: 'SKU图片/名字/ID',
        width: 50,
    },
    {
        id: 'product_name',
        name: '商品名',
        width: 40,
    },
    {
        id: 'unfulfilled_quantity',
        name: '下单未履约数',
        width: 25,
    },
    {
        id: 'in_transit_quantity',
        name: '已采购数',
        width: 25,
    },
    {
        id: 'in_stock_quantity',
        name: '应采购数',
        width: 25,
    },
    {
        id: 'sourcing_link',
        name: '1688采购链接',
        width: 25,
    },
];

export const PER_SHOP_ORDER_COLUMN_LIST = [
    {
        id: 'shop_name',
        name: '店铺',
    },
    {
        id: 'order_total',
        name: '异常订单总数',
        defaultSort: 'descend',
        sortFn: (a, b) => a.order_total - b.order_total,
    },
    {
        id: 'undivided_order',
        name: '未划分订单',
    },
    {
        id: 'normal_order',
        name: '常规订单',
    },
    {
        id: 'preorder_order',
        name: '预售订单',
    },
    {
        id: 'test_order',
        name: '内部测试',
    },
];

export const ORDER_MONITOR_COLUMN_LIST = [
    {
        id: 'name',
        name: 'Order',
        show_search: true,
    },
    {
        id: 'created_at',
        name: '创建日期',
        sortFn: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
        id: 'shop_name',
        name: '店铺',
        listOfFilter: [],
        filterFn: (list: string[], item) => list.some(name => item.shop_name.indexOf(name) !== -1),
    },
    {
        id: 'warehouse_name',
        name: '履约仓',
        listOfFilter: [],
        filterFn: (list: string[], item) => list.some(name => item.warehouse_name.indexOf(name) !== -1),
    },
    {
        id: 'country',
        name: '收货国家',
    },
    {
        id: 'tracking',
        name: '运单详情',
        width: '150px',
    },
    {
        id: 'order_type',
        name: '订单类型',
        listOfFilter: [
            { text: '未划分', value: 'undivided' },
            { text: '测试订单', value: 'test' },
            { text: '预售订单', value: 'preorder' },
            { text: '常规订单', value: 'normal' },
        ],
        filterFn: (list: string[], item) => list.some(name => item.order_type.indexOf(name) !== -1),
    },
    {
        id: 'order_status',
        name: '异常原因',
        listOfFilter: [
            { text: '缺货，未采购', value: 'failed_no_purchase' },
            { text: '缺货，采购在途', value: 'failed_in_transit' },
            { text: '仓库发货超时', value: 'late_fulfillment' },
            { text: '物流节点2天未更新', value: 'fulfillment_not_update' },
            { text: '物流时效超时', value: 'late_shipment' },
        ],
        filterFn: (list: string[], item) => list.some(name => item.order_status.indexOf(name) !== -1),
    },
    {
        id: 'not_shipped_waiting_time',
        name: '未发货等待时长',
        sortFn: (a, b) => a.not_shipped_waiting_time - b.not_shipped_waiting_time,
    },
    {
        id: 'custom_waiting_time',
        name: '客户等待时长',
        defaultSort: 'descend',
        sortFn: (a, b) => a.custom_waiting_time - b.custom_waiting_time,
    },
    {
        id: 'resolution_note',
        name: '处理方式',
    },
    {
        name: '操作',
    },
];
