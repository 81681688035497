import { AutoImportPurchaseRecordComponent } from './auto-import-purchase-record/auto-import-purchase-record.component';
import { ManualImportPurchaseRecordComponent } from './manual-import-purchase-record/manual-import-purchase-record.component';
import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { getShopListByFullSkuList } from '@shared/methods/order-dispatch.methods';
import { OrderDispatchCacheService } from '@services/order-dispatch-cache.service';
import * as dayjs from 'dayjs';
import { NotificationService } from '@services/notification.service';

@Component({
    selector: 'app-add-purchase-record',
    templateUrl: './add-purchase-record.component.html',
    styleUrls: ['./add-purchase-record.component.less'],
})
export class AddPurchaseRecordComponent implements OnInit {
    @Input() title: string;
    @Input() source: 'product_purchase' | 'restock_request';
    @Input() productList: any[];
    @Input() importMode: 'manual' | 'auto' = 'manual';

    @Output() saveSuccess = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter();

    @ViewChild('manualInputComp') manualInputComp: ManualImportPurchaseRecordComponent;
    @ViewChild('autoImportComp') autoImportComp: AutoImportPurchaseRecordComponent;

    shopList = [];
    restockRequestList = [];
    savingRecord = false;

    get fullSkuList() {
        return this.orderDispatchCacheService.allSku;
    }
    set fullSkuList(value) {
        this.orderDispatchCacheService.allSku = value;
    }

    constructor(
        private orderDispatchService: OrderDispatchService,
        private orderDispatchCacheService: OrderDispatchCacheService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.getRestockRequestList();

        if (this.fullSkuList) {
            this.shopList = getShopListByFullSkuList(this.fullSkuList);
        } else {
            this.orderDispatchService.getAllProductSku().then(res => {
                this.fullSkuList = res;
                this.shopList = getShopListByFullSkuList(res);
            });
        }
    }

    copyToRecord(data: any) {
        this.importMode = 'manual';
        this.manualInputComp.copyToRecord(data);
    }

    getRestockRequestList() {
        this.orderDispatchService.getRestockRequest().then(res => (this.restockRequestList = res));
    }

    productCreateSuccess() {
        this.orderDispatchService.getAllProductSku().then(res => {
            this.fullSkuList = res;
            this.shopList = getShopListByFullSkuList(res);
            this.orderDispatchService.getRestockRequest().then(result => (this.restockRequestList = result));
        });
    }

    saveRecord() {
        const importMode = this.importMode;
        let productPurchase;

        if (importMode === 'auto') {
            this.autoImportComp.savingRecord = true;
            productPurchase = this.autoImportComp.productPurchaseInfo;
        }

        if (importMode === 'manual') {
            this.manualInputComp.savingRecord = true;
            productPurchase = this.manualInputComp.productPurchase;
        }
        const promises: Promise<any>[] = productPurchase.product_list.map((productItem, i) => {
            const {
                destination_address,
                purchase_date,
                shipping_cost,
                deliver_eta,
                purchase_platform,
                purchase_order_id,
                order_content,
                logistics,
            } = productPurchase;
            const {
                product_sku,
                quantity,
                unit_cost,
                shop_id,
                product_id,
                restock_request_id,
                chinese_declaration_name,
                english_declaration_name,
                qc_ratio,
                check_requirement,
                qc_requirement,
            } = productItem;
            const reqBody = {
                product_sku,
                shop_id,
                product_id,
                quantity,
                unit_cost,
                shipping_cost,
                destination_address,
                purchase_platform,
                purchase_order_id,
                order_content,
                restock_request_id,
                deliver_eta,
                purchase_date,
                logistics: logistics?.filter(item => item.carrier && item.tracking_number),
                chinese_declaration_name,
                english_declaration_name,
                qc_ratio,
                check_requirement,
                qc_requirement,
            };
            if (this.importMode === 'auto') {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                reqBody['platform_sku'] = productItem.platform_sku;
            }
            return this.orderDispatchService.createProductPurchase(reqBody);
        });

        Promise.allSettled(promises).then(res => {
            const rejectedIndexArr = [];
            const fulfilledIndexArr = [];
            res.forEach((item, i) => {
                if (item.status === 'rejected') {
                    rejectedIndexArr.push(i);
                } else {
                    fulfilledIndexArr.push(i);
                }
            });

            this.savingRecord = false;
            productPurchase.product_list = productPurchase.product_list.filter(
                (item, i) => rejectedIndexArr.findIndex(rejIndex => rejIndex === i) !== -1
            );

            if (fulfilledIndexArr.length > 0) {
                this.notificationService.showGeneralSuccessMessage({
                    title: `${fulfilledIndexArr.length} records Success`,
                    message: 'success',
                });
                this.saveSuccess.emit();
                if (this.source === 'restock_request') {
                    productPurchase.destination_address = null;
                    productPurchase.purchase_date = dayjs().format('MM/DD/YYYY');
                    productPurchase.deliver_eta = null;
                    productPurchase.purchase_platform = null;
                    productPurchase.purchase_order_id = null;
                    productPurchase.shipping_cost = 0;
                    productPurchase.order_content = null;
                }
            }

            this.getRestockRequestList();

            if (rejectedIndexArr.length > 0) {
                this.notificationService.showGeneralErrorMessage({
                    title: `${rejectedIndexArr.length} records failed, please try again.`,
                    message: 'error',
                });
            }

            if (this.importMode === 'auto') {
                this.autoImportComp.savingRecord = false;
            }
            if (this.importMode === 'manual') {
                this.manualInputComp.savingRecord = false;
            }
        });
    }

    cancelRecord() {
        this.close.emit();
    }
}
