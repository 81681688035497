<div class="p-20">
    <div class="fs-20 mt-20">{{'WELCOME_TO_LIFO' | translate}}</div>

    <div class="d-flex justify-content-start align-items-center mt-20 language-settings">
        <div class="mr-20">{{'LANGUAGE_SETTINGS' | translate}}</div>
        <nz-button-group>
            <button nz-button [nzType]="currentLang === LangsEnum.en?'primary':''" (click)="changeLang(LangsEnum.en)">English</button>
            <button nz-button [nzType]="currentLang === LangsEnum.zh?'primary':''" class="mr-20" (click)="changeLang(LangsEnum.zh)">
                简体中文
            </button>
        </nz-button-group>
    </div>

    <div class="main-content" style="margin-top: 50px" *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
        <section class="d-flex align-items-end justify-content-between btn-operations">
            <h4 class="main-content-title">异常订单监控</h4>
        </section>

        <div class="sub-title">各店铺当前异常订单数</div>

        <section class="data-table">
            <nz-table #perShopOrderTable [nzData]="perShopOrders" [nzLoading]="unfulfilledOrderLoading">
                <thead>
                    <tr>
                        <ng-container *ngFor="let column of perShopOrderColumnList">
                            <th
                                [nzSortFn]="column.sortFn"
                                [nzFilters]="column.listOfFilter"
                                [nzFilterFn]="column.filterFn"
                                [nzWidth]="column.width"
                                [nzSortOrder]="column.defaultSort"
                            >
                                {{ column.name }}
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of perShopOrderTable.data">
                        <tr>
                            <td>{{data.shop_name}}</td>
                            <td>{{data.order_total}}个</td>
                            <td>{{data.undivided_order}}个</td>
                            <td>{{data.normal_order}}个</td>
                            <td>{{data.preorder_order}}个</td>
                            <td>{{data.test_order}}个</td>
                        </tr>
                    </ng-container>
                </tbody>
            </nz-table>
        </section>

        <div class="sub-title d-flex align-items-center justify-content-between mt-20">
            异常客户订单监控
            <div>
                <nz-range-picker
                    nzFormat="MM/dd/yyyy"
                    [(ngModel)]="orderCreateTime"
                    style="width: 300px; margin-right: 30px"
                    (ngModelChange)="onOrderTimeChange()"
                ></nz-range-picker>
                <button
                    nz-button
                    nzType="primary"
                    [disabled]="!this.setOfCheckedOrderId.size"
                    [nzLoading]="exportingOrders"
                    (click)="exportOrders()"
                >
                    订单导出
                </button>
                <button nz-button nzType="primary" (click)="unfulfilledOrderModalVisible = true" style="margin-left: 20px">
                    添加异常订单
                </button>
            </div>
        </div>

        <section class="d-flex align-items-center justify-content-between filter-status">
            <section class="tabs">
                <div
                    *ngFor="let item of orderTypes"
                    [ngClass]="{selected: selectedOrderType === item.value}"
                    (click)="changeUnfulfilledOrders(item.value, isProcessed)"
                >
                    {{item.name}}（{{item.count}}）
                </div>
            </section>
        </section>

        <section class="d-flex align-items-center justify-content-between filter-status small-filter-status">
            <section class="tabs">
                <div [ngClass]="{selected: !isProcessed}" (click)="changeUnfulfilledOrders(selectedOrderType, false)">未处理</div>
                <div [ngClass]="{selected: isProcessed}" (click)="changeUnfulfilledOrders(selectedOrderType, true)">已处理</div>
            </section>
        </section>

        <section class="data-table">
            <nz-table
                #orderMonitorTable
                [nzData]="unfulfilledOrders$ | async"
                [nzLoading]="unfulfilledOrderLoading"
                [nzScroll]="{ x: '1650px' }"
            >
                <thead>
                    <tr>
                        <th [nzWidth]="'40px'" [(nzChecked)]="allOrderChecked" (nzCheckedChange)="onAllOrderChecked($event)"></th>
                        <ng-container *ngFor="let column of orderMonitorColumnList">
                            <th
                                *ngIf="!column.show_search"
                                [nzSortFn]="column.sortFn"
                                [nzFilters]="column.listOfFilter"
                                [nzFilterFn]="column.filterFn"
                                [nzWidth]="column.width"
                                [nzSortOrder]="column.defaultSort"
                                (nzFilterChange)="filterOrderChange($event, column)"
                            >
                                {{ column.name }}
                            </th>
                            <th
                                *ngIf="column.show_search"
                                [nzSortFn]="column.sortFn"
                                [nzSortOrder]="column.defaultSort"
                                [nzWidth]="column.width"
                            >
                                {{ column.name }}
                                <app-table-search-dropdown
                                    [defaultProductList]="defaultUnfulfilledOrders"
                                    [name]="column.id"
                                    (searchList)="searchResultUnfulfilledOrder($event)"
                                    (resetList)="resetResultUnfulfilledOrder()"
                                ></app-table-search-dropdown>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of orderMonitorTable.data">
                        <td [nzChecked]="setOfCheckedOrderId.has(data.id)" (nzCheckedChange)="onOrderItemChecked(data.id, $event)"></td>
                        <td><span class="btn-link" (click)="goOrderDetail(data)">{{data.name}}</span></td>
                        <td>{{data.created_at | date:'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td>{{data.shop_name}}</td>
                        <td>{{data.warehouse_name}}</td>
                        <td>{{data.shipping_country}}</td>
                        <td>
                            <span *ngIf="data.last_tracked_at; else notTrackAt">
                                {{data.last_tracked_at | date:'MM/dd HH:mm'}}
                                <i
                                    nz-icon
                                    nzType="eye"
                                    nzTheme="outline"
                                    style="margin-left: 10px; color: #434bac; cursor: pointer"
                                    (click)="trackingDetailModalVisible = true; selectedOrderId = data.id"
                                ></i>
                            </span>
                            <ng-template #notTrackAt>/</ng-template>
                        </td>
                        <td>
                            <div
                                class="order-type"
                                [ngClass]="{
                                    test: data.order_type === 'test',
                                    preorder: data.order_type === 'preorder',
                                    normal: data.order_type === 'normal',
                                    undivided: data.order_type === 'undivided'
                                }"
                            >
                                {{orderTypeMap.get(data.order_type)}}
                            </div>
                        </td>
                        <td>
                            <div class="reason">{{orderStatusMap.get(data.order_status)}}</div>
                        </td>
                        <td>
                            <span *ngIf="data.not_shipped_waiting_time" [ngClass]="{red: data.not_shipped_waiting_time > 72}">
                                {{data.not_shipped_waiting_time}} 小时
                            </span>
                        </td>
                        <td>
                            <span [ngClass]="{red: data.custom_waiting_time > 15}">
                                {{data.custom_waiting_time + (data.custom_waiting_time > 1 ? ' days' : ' day')}}
                            </span>
                        </td>
                        <td>
                            <div
                                nz-tooltip
                                [nzTooltipTitle]="data.exception?.resolution_status"
                                [nzTooltipTrigger]="data.exception?.resolution_status?.length > 18 ? 'hover' : null"
                                style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; overflow: hidden"
                            >
                                {{data.exception?.resolution_status ? data.exception?.resolution_status : '未处理'}}
                            </div>
                        </td>
                        <td>
                            <button nz-button nzType="primary" (click)="goOrderDetail(data)">处理并备注</button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </section>
    </div>

    <div class="main-content" style="margin-top: 50px" *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
        <section class="d-flex align-items-end justify-content-between btn-operations">
            <h4 class="main-content-title">当前订单SKU各仓缺货情况</h4>
            <section>
                <button nz-button nzType="primary" (click)="outOfStockRefresh()">刷新</button>
            </section>
        </section>

        <section class="d-flex align-items-center justify-content-between filter-status">
            <section class="tabs">
                <div [ngClass]="{selected: selectedWarehouse === 'HZ Warehouse'}" (click)="changeWarehouse('HZ Warehouse')">
                    HZ Warehouse
                </div>
                <div [ngClass]="{selected: selectedWarehouse === 'SF Warehouse'}" (click)="changeWarehouse('SF Warehouse')">
                    SF Warehouse
                </div>
            </section>
            <section>
                <button nz-button nzType="primary" [nzLoading]="isDownloadingExcel" (click)="exportOutOfStockSku()">导出缺货SKU</button>
            </section>
        </section>

        <section class="data-table">
            <nz-table #outOfStockTable [nzData]="outOfStockList" [nzLoading]="outOfStockLoading">
                <thead>
                    <tr>
                        <ng-container *ngFor="let column of outOfStockColumnList">
                            <th
                                *ngIf="!column.show_search"
                                [nzSortFn]="column.sortFn"
                                [nzFilters]="column.listOfFilter"
                                [nzFilterFn]="column.filterFn"
                                [nzWidth]="column.width"
                            >
                                {{ column.name }}
                            </th>
                            <th *ngIf="column.show_search" [nzSortFn]="column.sortFn" [nzWidth]="column.width">
                                {{ column.name }}
                                <app-table-search-dropdown
                                    [defaultProductList]="defaultOutOfStockList"
                                    [name]="column.id"
                                    (searchList)="searchResultOutOfStockList($event)"
                                    (resetList)="resetResultOutOfStockList()"
                                ></app-table-search-dropdown>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of outOfStockTable.data">
                        <tr>
                            <td class="d-flex align-items-center">
                                <img [src]="data.variant_image || 'assets/svg/sku_nopic.svg'" class="variant-img" />
                                <div>
                                    {{data.variant_name}} <br />
                                    {{data.sku}}
                                </div>
                            </td>
                            <td><span class="btn-link" (click)="goProductDetail(data)">{{data.product_name}}</span></td>
                            <td>{{data.shop_name}}</td>
                            <td>{{data.unfulfilled_quantity}}</td>
                            <td>{{data.in_stock_quantity}} / {{data.in_transit_quantity}}</td>
                            <td>{{getPurchaseCount(data)}}</td>
                            <td>
                                <i
                                    nz-icon
                                    nzType="left"
                                    nzTheme="outline"
                                    class="expand-icon"
                                    [ngClass]="{active: expandSet.has(data.sku)}"
                                    (click)="onExpandChange(data.sku)"
                                ></i>
                                <button nz-button nzType="primary" (click)="batchProcessingByOutOfStock(data.sku)">批量处理</button>
                            </td>
                        </tr>
                        <tr [nzExpand]="expandSet.has(data.sku)" class="expand-pending-order-tr">
                            <nz-table
                                #pendingOrderBySkuTable
                                [nzData]="pendingOrderBySkuMap.get(data.sku)"
                                [nzFrontPagination]="false"
                                [nzShowPagination]="false"
                                [nzLoading]="!pendingOrderBySkuMap.has(data.sku)"
                            >
                                <thead>
                                    <tr>
                                        <th [nzWidth]="'100px'">Order</th>
                                        <th [nzWidth]="'100px'">创建日期</th>
                                        <th [nzWidth]="'100px'">店铺</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let oData of pendingOrderBySkuTable.data">
                                        <td><span class="btn-link" (click)="goOrderDetail(oData)">{{oData.name}}</span></td>
                                        <td>{{oData.created_at | date:'MM/dd/yyyy HH:mm:ss'}}</td>
                                        <td>{{oData.shop_name}}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </tr>
                    </ng-container>
                </tbody>
            </nz-table>
        </section>
    </div>

    <div class="mt-20 main-content" *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
        <section class="d-flex align-items-end justify-content-between btn-operations">
            <h4 class="main-content-title">
                等待派仓订单 <br />
                <b>{{ defaultPendingOrderList?.length }}</b>
            </h4>
            <section>
                <button nz-button nzType="primary" (click)="getPendingOrder()" style="margin-right: 15px">刷新</button>
                <button nz-button nzType="primary" [disabled]="setOfCheckedId.size === 0" (click)="batchProcessingByPendingOrder()">
                    批量处理
                </button>
            </section>
        </section>

        <section class="data-table">
            <nz-table #unassignedTable nzShowPagination [nzData]="pendingOrderList" [nzLoading]="pendingOrderLoading">
                <thead>
                    <tr>
                        <th [nzWidth]="'40px'" [(nzChecked)]="allChecked" (nzCheckedChange)="onAllChecked($event)"></th>
                        <ng-container *ngFor="let column of pendingOrderColumnList">
                            <th
                                *ngIf="!column.show_search"
                                [nzSortFn]="column.sortFn"
                                [nzFilters]="column.listOfFilter"
                                [nzFilterFn]="column.filterFn"
                                [nzWidth]="column.width"
                                [nzSortOrder]="column.defaultSort"
                            >
                                {{ column.name }}
                            </th>
                            <th
                                *ngIf="column.show_search"
                                [nzSortFn]="column.sortFn"
                                [nzSortOrder]="column.defaultSort"
                                [nzWidth]="column.width"
                            >
                                {{ column.name }}
                                <app-table-search-dropdown
                                    [defaultProductList]="defaultPendingOrderList"
                                    [name]="column.id"
                                    (searchList)="searchResultPendingOrder($event)"
                                    (resetList)="resetResultPendingOrder()"
                                ></app-table-search-dropdown>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of unassignedTable.data">
                        <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                        <td><span class="btn-link" (click)="goOrderDetail(data)">{{data.name}}</span></td>
                        <td>{{data.created_at | date:'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td>{{data.shop_name}}</td>
                        <td>{{data.warehouse_name}}</td>
                        <td>{{data.waiting_time + (data.waiting_time > 1 ? ' days' : ' day')}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </section>
    </div>
</div>

<nz-modal
    nzTitle="Tracking Detail"
    [(nzVisible)]="trackingDetailModalVisible"
    (nzOnCancel)="trackingDetailModalVisible = false;selectedOrderId = null"
    (nzOnOk)="trackingDetailModalVisible = false;selectedOrderId = null"
>
    <app-tracking-detail *ngIf="selectedOrderId" [orderId]="selectedOrderId"></app-tracking-detail>
</nz-modal>

<nz-modal
    nzTitle="手动添加异常订单"
    [(nzVisible)]="unfulfilledOrderModalVisible"
    nzCancelText="取消"
    nzOkText="确认添加"
    [nzOkDisabled]="addUnfulfilledOrderDisabled"
    [nzOkLoading]="orderSubmitting"
    (nzOnCancel)="unfulfilledOrderModalVisible = false"
    (nzOnOk)="addUnfulfilledOrder()"
    nzWidth="700px"
>
    <section class="d-flex flex-wrap unfulfilled-order-box">
        <div class="form-item">
            <label>Order Name 订单号 <span class="red">*</span></label>
            <div>
                <input nz-input [(ngModel)]="unfulfilledOrderParams.id" (input)="addOrderErrorMsg = null" />
                <p *ngIf="addOrderErrorMsg" class="red" style="font-size: 12px; margin: 0">{{addOrderErrorMsg}}</p>
            </div>
        </div>
        <div class="form-item">
            <label>订单类型</label>
            <div>
                <nz-select [(ngModel)]="unfulfilledOrderParams.order_type" class="width-100p">
                    <nz-option nzValue="undivided" nzLabel="未划分"></nz-option>
                    <nz-option nzValue="normal" nzLabel="常规订单"></nz-option>
                    <nz-option nzValue="preorder" nzLabel="预售订单"></nz-option>
                    <nz-option nzValue="test" nzLabel="内部测试订单"></nz-option>
                </nz-select>
            </div>
        </div>
        <div class="form-item">
            <label>异常原因 <span class="red">*</span></label>
            <div>
                <nz-select [(ngModel)]="unfulfilledOrderParams.exception.exception_status" class="width-100p">
                    <nz-option *ngFor="let item of orderStatusList" [nzValue]="item.value" [nzLabel]="item.name"></nz-option>
                </nz-select>
            </div>
        </div>
        <div class="form-item">
            <label>异常处理方式 <span class="red">*</span></label>
            <div>
                <nz-select nzShowSearch [(ngModel)]="unfulfilledOrderParams.exception.resolution_status" class="width-100p">
                    <nz-option *ngFor="let item of resolutionList" [nzValue]="item" [nzLabel]="item"></nz-option>
                </nz-select>
            </div>
        </div>
        <div class="form-item" *ngIf="unfulfilledOrderParams.exception.exception_status === 'other'">
            <label>异常原因备注 <span class="red">*</span></label>
            <div>
                <textarea nz-input [(ngModel)]="unfulfilledOrderParams.exception.exception_note" style="height: 130px"></textarea>
            </div>
        </div>
        <div class="form-item">
            <label>处理方式备注</label>
            <div>
                <textarea nz-input [(ngModel)]="unfulfilledOrderParams.exception.resolution_note" style="height: 130px"></textarea>
            </div>
        </div>
        <div class="form-item" style="width: 100%">
            <label nz-checkbox [(ngModel)]="unfulfilledOrderParams.exception.customer_care">同时转客服处理？</label>
        </div>
    </section>
</nz-modal>
