<section *ngIf="productInfo" class="product-info-item">
    <section class="info">
        <img class="product-img" [src]="productInfo.img_url" />
        <section class="text-info">
            <section class="product-name">{{ productInfo.title }}</section>
            <section *ngIf="productInfo.sub_title" class="additional-info">{{ productInfo.sub_title }}</section>
            <section *ngIf="productInfo.sku" class="additional-info">SKU: {{ productInfo.sku }}</section>
            <section class="additional-info">Type: {{ productInfo.product_type | titlecase }}</section>
        </section>
    </section>

    <section *ngIf="!removeCount" class="count">
        <span>${{ productInfo.product_price | number: '1.2-2' }} x </span>
        <span *ngIf="!showEdit; else editTemplate">{{ productInfo.product_quantity }}</span>
        <ng-template #editTemplate>
            <nz-input-number [nzMin]="1" [nzStep]="1" [(ngModel)]="productInfo.product_quantity"></nz-input-number>
        </ng-template>
    </section>

    <section class="price ml-20">
        <span
            *ngIf="showEdit"
            class="cursor-pointer"
            style="color: #414bb2; text-decoration: underline; margin-bottom: 40px"
            (click)="removeClick()"
        >
            Remove
        </span>
        <span> ${{ getTotalPrice(productInfo.product_price, productInfo.product_quantity || 0) | number: '1.2-2' }} </span>
    </section>
</section>
