import { ProductInfo } from '@shared/type/order-dispatch.type';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-product-info',
    templateUrl: './product-info.component.html',
    styleUrls: ['./product-info.component.less'],
})
export class ProductInfoComponent implements OnInit {
    @Input() removeCount = false;
    @Input() showEdit = false;
    @Input() productInfo: ProductInfo;

    @Output() remove = new EventEmitter<ProductInfo>();

    constructor() {}

    getTotalPrice(price, count) {
        return Number(price) * count;
    }

    ngOnInit(): void {}

    removeClick() {
        this.remove.emit(this.productInfo);
    }
}
