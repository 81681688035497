/* eslint-disable max-len */
// Component
import { LifoRichEditorComponent } from './components/lifo-rich-editor/lifo-rich-editor.component';
import { GeneralDialogComponent } from './components/general-dialog/general-dialog.component';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { AddPurchaseRecordComponent } from './components/add-purchase-record/add-purchase-record.component';
import { ModuleHeaderComponent } from './components/module-header/module-header.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { AddOptionDialogComponent } from './components/add-option-dialog/add-option-dialog.component';
import { VisitCreatorHubModalComponent } from './components/visit-creator-hub-modal/visit-creator-hub-modal.component';
import { ImageFullscreenComponent } from './components/image-fullscreen/image-fullscreen.component';
import { EditPopoverComponent } from './components/edit-popover/edit-popover.component';
import { ChooseVariantImageComponent } from './components/choose-variant-image/choose-variant-image.component';
import { PackageInfoSettingComponent } from './components/package-info-setting/package-info-setting.component';
import { StoreLabelingComponent } from './components/store-labeling/store-labeling.component';
import { LabelCommonComponent } from './components/store-labeling/label-common/label-common.component';
import { ListLabelingModalComponent } from './components/store-labeling/list-labeling-modal/list-labeling-modal.component';
import { FormItemComponent } from './components/form-item/form-item.component';
import { AntdEmptyTableComponent } from './components/antd-global-config/antd-global-config.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MassOrderingStatisticsComponent } from './components/mass-ordering-statistics/mass-ordering-statistics.component';
import { MassOrderingConfirmTableComponent } from './components/mass-ordering-confirm-table/mass-ordering-confirm-table.component';
import { ProductInfoComponent } from '@shared/components/product-info/product-info.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { TableSearchDropdownComponent } from '@shared/components/table-search-dropdown/table-search-dropdown.component';
import { CampaignHistoryComponent } from './module-shared-components/campaign-history/campaign-history.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { EditCampaignInformationModalComponent } from './components/edit-campaign-information-modal/edit-campaign-information-modal.component';
import { ManualImportPurchaseRecordComponent } from './components/add-purchase-record/manual-import-purchase-record/manual-import-purchase-record.component';
import { AutoImportPurchaseRecordComponent } from './components/add-purchase-record/auto-import-purchase-record/auto-import-purchase-record.component';
import { ShippingSchedulePlanComponent } from './components/shipping-schedule/shipping-schedule.component';
import { ShippingBoxListComponent } from './components/shipping-box-list/shipping-box-list.component';
import { LogisticRecordErrorComponent } from '@pages/logistics-management/mass-ordering/logistic-record-error/logistic-record-error.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { ProductInfoInListComponent } from './components/product-info-in-list/product-info-in-list.component';
import { KitTooltipComponent } from './components/kit-tooltip/kit-tooltip.component';
import { CustomInfoComponent } from './components/custom-info/custom-info.component';
import { FollowerInfoComponent } from './components/follower-info/follower-info.component';
import { TrackingDetailComponent } from './components/tracking-detail/tracking-detail.component';

// Directive
import { ImgVideoPreviewDirective } from './directive/file-preview.directive';
import { StatusBtnDirective } from './directive/status-btn.directive';
import { EchartsDirective } from './directive/echarts.directive';
import { ImageDragSortDirective } from './directive/image-drag-sort.directive';

// Pipe
import { ExtendDatePipe } from './pipe/date.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { SplitPipe } from './pipe/split.pipe';

export const componentArr = [
    SubmenuComponent,
    LifoRichEditorComponent,
    GeneralDialogComponent,
    ModuleHeaderComponent,
    SearchFilterComponent,
    AddOptionDialogComponent,
    VisitCreatorHubModalComponent,
    ImageFullscreenComponent,
    EditPopoverComponent,
    ChooseVariantImageComponent,
    AddPurchaseRecordComponent,
    PackageInfoSettingComponent,
    StoreLabelingComponent,
    ListLabelingModalComponent,
    LabelCommonComponent,
    FormItemComponent,
    AntdEmptyTableComponent,
    NotificationComponent,
    MassOrderingStatisticsComponent,
    MassOrderingConfirmTableComponent,
    ProductInfoComponent,
    AddressFormComponent,
    TableSearchDropdownComponent,
    CampaignHistoryComponent,
    ProductDetailsComponent,
    EditCampaignInformationModalComponent,
    ManualImportPurchaseRecordComponent,
    AutoImportPurchaseRecordComponent,
    ShippingSchedulePlanComponent,
    ShippingBoxListComponent,
    LogisticRecordErrorComponent,
    AddressModalComponent,
    ProductInfoInListComponent,
    KitTooltipComponent,
    CustomInfoComponent,
    FollowerInfoComponent,
    TrackingDetailComponent,
];

export const directiveArr = [ImgVideoPreviewDirective, StatusBtnDirective, EchartsDirective, ImageDragSortDirective];

export const pipeArr = [ExtendDatePipe, SafeHtmlPipe, SplitPipe];
