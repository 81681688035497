import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '@services/token.service';
import { take } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

interface IRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    data?: any;
    retryTime?: number;
    noAuth?: boolean;
    contentType?: string;
    timeout?: number; // ms
}
@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient, private tokenService: TokenService, private router: Router) {}

    async sendRequest<T>(requestOptions: IRequestOptions, host: string, errorHandlerType: 'string' | 'object' = 'string') {
        if (requestOptions.noAuth) {
            return this.sendRequest$(requestOptions, host, errorHandlerType);
        }

        // Token validation
        if (!this.tokenService.getToken()) {
            if (!this.tokenService.getRefreshToken()) {
                // No refresh token
                this.router.navigateByUrl('/sign-in');
            } else {
                // Get idToken by refreshToken
                await this.getTokenByRefreshToken(this.tokenService.getRefreshToken());
            }
        }

        try {
            return await this.sendRequest$(requestOptions, host, errorHandlerType);
        } catch (e) {
            if (e.status === 401) {
                await this.getTokenByRefreshToken(this.tokenService.getRefreshToken());
                return this.sendRequest$(requestOptions, host, errorHandlerType);
            }

            throw e;
        }
    }

    getTokenByRefreshToken(refreshToken: string) {
        return this.sendRequest$(
            {
                method: 'POST',
                url: '/login/firebase/refresh_token',
                data: { refresh_token: refreshToken },
            },
            environment.dataApiService
        )
            .then(({ idToken }) => this.tokenService.setToken(idToken))
            .catch(() => {
                this.tokenService.removeAllToken();
                this.router.navigate(['/sign-in']);
            });
    }

    private sendRequest$<T>(requestOptions: IRequestOptions, host: string, errorHandlerType: 'string' | 'object' = 'string'): any {
        let headers = new HttpHeaders();

        if (!requestOptions.noAuth) {
            headers = headers.set('Authorization', this.tokenService.getToken());
        }

        if (requestOptions.timeout) {
            headers = headers.set('timeout', String(requestOptions.timeout));
        }

        const url = `${host ?? environment.campaignService}${requestOptions.url}`;

        switch (requestOptions.method) {
            case 'GET':
                return this.http.get<T>(url, { headers }).pipe(take(1)).toPromise();
            case 'DELETE':
                return this.http.delete<T>(url, { headers }).pipe(take(1)).toPromise();
            case 'POST':
                return this.http.post<T>(url, requestOptions.data, { headers }).pipe(take(1)).toPromise();
            case 'PUT':
                return this.http.put<T>(url, requestOptions.data, { headers }).pipe(take(1)).toPromise();
        }
    }
}
