import { Component, Input, OnInit, ElementRef, Output, EventEmitter, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

import { ProductInventoryService } from '@services/product-inventory.service';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ImagePreviewFullscreenService } from '@services/image-preview-fullscreen.service';

@Component({
    selector: 'app-image-fullscreen',
    templateUrl: './image-fullscreen.component.html',
    styleUrls: ['./image-fullscreen.component.less'],
})
export class ImageFullscreenComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() images: { src: string }[];

    @Input() activatedItemIndex: number;

    @Input() isShowCropper: boolean;

    @Output() destroy = new EventEmitter<null>();

    @ViewChild('angularCropper') public angularCropper: CropperComponent;

    isCropper = false;
    cropperConfig = {
        aspectRatio: 1,
    };
    imgMaxWidth: number;
    imgMaxHeight: number;
    imgOptions = {
        imgWidth: null,
        imgHeight: null,
        ratio: null,
    };
    submitting: boolean;
    removeBgLoading: boolean;
    removeTextLoading: boolean;

    get activatedImage() {
        return (this.images || [])[this.activatedItemIndex || 0];
    }

    constructor(
        private ele: ElementRef,
        private productInventoryService: ProductInventoryService,
        private message: NzMessageService,
        private imagePreviewFullscreen: ImagePreviewFullscreenService
    ) {}

    ngOnInit(): void {
        window.removeEventListener('keydown', null);
        window.addEventListener('keydown', e => {
            if (e.code === 'ArrowLeft') {
                this.next(-1);
            }

            if (e.code === 'ArrowRight') {
                this.next(1);
            }
        });
        this.imgMaxWidth = Math.floor(window.innerWidth - 200);
        this.imgMaxHeight = Math.floor(window.innerHeight * 0.7);
        this.calculateImgRatio();
    }

    ngOnDestroy() {
        window.removeEventListener('keydown', null);
    }

    ngAfterViewInit() {
        // const ele = this.ele.nativeElement as HTMLDivElement;
        // const requestFullScreen =
        //     // eslint-disable-next-line @typescript-eslint/dot-notation
        //     ele.requestFullscreen || ele['webkitRequestFullscreen'] || ele['mozRequestFullScreen'] || ele['msRequestFullscreen'];
        // requestFullScreen.call(ele);
        // ele.addEventListener('fullscreenchange', () => {
        //     if (!document.fullscreenElement) {
        //         this.destroy.emit();
        //     }
        // });
    }

    next(step: number) {
        this.activatedItemIndex += step;

        if (this.activatedItemIndex >= this.images.length) {
            this.activatedItemIndex = 0;
        }

        if (this.activatedItemIndex < 0) {
            this.activatedItemIndex = this.images.length - 1;
        }
        this.isCropper = false;
        this.calculateImgRatio();
    }

    changeActiveIndex(i) {
        this.activatedItemIndex = i;
        this.isCropper = false;
        this.calculateImgRatio();
    }

    close() {
        this.destroy.emit();
    }

    calculateImgRatio() {
        const img = new Image();
        img.src = this.activatedImage?.src;
        img.onload = () => {
            if (img.height > this.imgMaxHeight) {
                this.imgOptions.imgHeight = this.imgMaxHeight;
                this.imgOptions.ratio = img.height / this.imgOptions.imgHeight;
                this.imgOptions.imgWidth = img.width / this.imgOptions.ratio;
            } else {
                this.imgOptions.imgHeight = img.height;
                this.imgOptions.imgWidth = img.width;
                this.imgOptions.ratio = 1;
            }
            if (this.imgOptions.imgWidth > this.imgMaxWidth) {
                this.imgOptions.imgWidth = this.imgMaxWidth;
                this.imgOptions.ratio = img.width / this.imgOptions.imgWidth;
                this.imgOptions.imgHeight = img.height / this.imgOptions.ratio;
            }
        };
    }

    getCropBoxData(src) {
        this.submitting = true;
        const params = {
            src,
            top: Math.floor(this.angularCropper.cropper.getCropBoxData().top * this.imgOptions.ratio),
            left: Math.floor(this.angularCropper.cropper.getCropBoxData().left * this.imgOptions.ratio),
            width: Math.floor(this.angularCropper.cropper.getCropBoxData().width * this.imgOptions.ratio),
            height: Math.floor(this.angularCropper.cropper.getCropBoxData().height * this.imgOptions.ratio),
        };
        this.productInventoryService
            .cropImage(params)
            .then(res => {
                this.isCropper = false;
                this.submitting = false;
                this.images.push({
                    src: res.src,
                });
                this.imagePreviewFullscreen.updateImageList(this.images);
            })
            .catch(() => {
                this.submitting = false;
            });
    }

    removeImgBackground(src: string) {
        this.removeBgLoading = true;
        this.productInventoryService
            .removeImageBackground({ src })
            .then(res => {
                this.images.push({ src: res.src });
                this.imagePreviewFullscreen.updateImageList(this.images);
            })
            .catch(() => this.message.error('Remove background failed.'))
            .finally(() => (this.removeBgLoading = false));
    }

    removeImgText(src: string) {
        this.removeTextLoading = true;
        this.productInventoryService
            .removeImageText({ src })
            .then(res => {
                this.images.push({ src: res.src });
                this.imagePreviewFullscreen.updateImageList(this.images);
            })
            .catch(() => this.message.error('Remove text failed.'))
            .finally(() => (this.removeTextLoading = false));
    }
}
