import { Directive, Input, AfterViewInit, ElementRef, OnChanges, SimpleChanges, ComponentFactoryResolver } from '@angular/core';

import * as echarts from 'echarts/core';
import {
    GridComponent,
    GridComponentOption,
    TooltipComponent,
    DatasetComponent,
    LegendComponent,
    DataZoomComponent,
    ToolboxComponent,
} from 'echarts/components';
import { LineChart, LineSeriesOption, PieSeriesOption, PieChart, BarChart, BarSeriesOption } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
    GridComponent,
    LineChart,
    BarChart,
    PieChart,
    CanvasRenderer,
    TooltipComponent,
    DatasetComponent,
    LegendComponent,
    DataZoomComponent,
    ToolboxComponent,
]);

export type EchartsOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption | BarSeriesOption | PieSeriesOption>;
export { echarts };

@Directive({
    selector: '[appEcharts]',
})
export class EchartsDirective implements AfterViewInit, OnChanges {
    @Input() initialOption: EchartsOption;
    @Input() optionToBeUpdate: EchartsOption;

    private charts: echarts.EChartsType;

    constructor(private el: ElementRef) {}

    ngOnChanges(change: SimpleChanges) {
        if (change.optionToBeUpdate?.currentValue) {
            if (this.initialOption.isClear) {
                this.charts.clear();
                this.charts.setOption({ ...this.initialOption, ...this.optionToBeUpdate });
            } else {
                this.charts.setOption(this.optionToBeUpdate);
            }
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.charts = echarts.init(this.el.nativeElement);
            this.charts.setOption(this.initialOption);
            window.onresize = () => {
                this.charts.resize();
            };
        }, 0);
    }

    private showLoading(
        config: { maskColor?: string; text?: string; color?: string; textColor?: string; zlevel?: number } = {
            maskColor: 'transparent',
            text: '',
        }
    ) {
        this.charts.showLoading(config);
    }

    private hideLoading() {
        this.charts.hideLoading();
    }
}
