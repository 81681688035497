import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'date',
})
export class ExtendDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale);
    }

    transform(value: string, format?: string, timezone?: string, local?: string): string {
        const val = dayjs(value);
        return super.transform(val.isValid() ? val.toString() : null, format, timezone, local);
    }
}
