<section class="fullscreen-img">
    <header class="img-header">
        <span>{{ activatedImage?.src }}</span>
        <i nz-icon nzType="close" nzTheme="outline" (click)="close()"></i>
    </header>

    <section class="main-content">
        <i nz-icon nzType="left-circle" nzTheme="outline" (click)="next(-1)"></i>

        <div style="max-width: calc(100% - 200px)">
            <div *ngIf="isShowCropper" class="text-align-right" style="margin-bottom: 10px">
                <button nz-button nzSize="normal" [nzType]="isCropper ? 'primary' : 'default'" (click)="isCropper = true">Crop</button>
                <button
                    nz-button
                    nzSize="normal"
                    nzType="default"
                    [nzLoading]="removeBgLoading"
                    style="margin-left: 10px"
                    (click)="removeImgBackground(activatedImage?.src)"
                >
                    Remove Background
                </button>
                <button
                    nz-button
                    nzSize="normal"
                    nzType="default"
                    [nzLoading]="removeTextLoading"
                    style="margin-left: 10px"
                    (click)="removeImgText(activatedImage?.src)"
                >
                    Remove Text
                </button>
            </div>
            <img [hidden]="isCropper" [src]="activatedImage?.src" [height]="imgOptions.imgHeight" [width]="imgOptions.imgWidth" alt="" />
            <div [ngStyle]="{height: imgOptions.imgHeight + 'px', width: imgOptions.imgWidth + 'px'}" *ngIf="isCropper">
                <angular-cropper #angularCropper [cropperOptions]="cropperConfig" [imageUrl]="activatedImage?.src"></angular-cropper>
            </div>
            <div *ngIf="isShowCropper" [ngStyle]="{visibility: isCropper ? 'visible' : 'hidden'}" class="text-align-right cropper-btns">
                <button nz-button nzType="default" (click)="isCropper = false">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </button>
                <button nz-button nzType="default" [disabled]="submitting" (click)="getCropBoxData(activatedImage?.src)">
                    <i nz-icon nzType="check" nzTheme="outline"></i>
                </button>
            </div>
        </div>

        <i nz-icon nzType="right-circle" nzTheme="outline" (click)="next(1)"></i>
    </section>

    <footer class="image-list">
        <img
            *ngFor="let img of images; let i = index"
            [src]="img.src"
            (click)="changeActiveIndex(i)"
            [ngStyle]="{
                opacity: i === activatedItemIndex ? '1' : '0.3'
            }"
        />
    </footer>
</section>
